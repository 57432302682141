import { useState } from 'react'
import RichTextEditor from '../Event/RichEditor';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Typography } from '@mui/material';
import { MdDelete } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import RemoveIcon from "@mui/icons-material/Remove";

function Faqs({ faqsDetailsData, setFaqsDetailsData }) {

  const addData = () => {
    setFaqsDetailsData([...faqsDetailsData, {
      groupHeading: "FAQ",
      description: "",
      position: 0,
      publish: false,
      data: [],
    }]);
    toast.success(" New FAQ group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (faqsDetailsData.length > 0) {
      const updatedData = faqsDetailsData.slice(0, -1);
      setFaqsDetailsData(updatedData);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  
  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setFaqsDetailsData(faqsDetailsData.filter((_, i) => i !== index));
      toast.success("FAQ group deleted successfully!", {

        position: toast.POSITION.TOP_RIGHT,

      });
    }
  };

  const handleAddFaqs = (index) => {
    const updatedData = [...faqsDetailsData];
    updatedData[index].data.push({
      question: "",
      answer: "",
      position: updatedData[index].data.length + 1,
    });
    setFaqsDetailsData(updatedData);
  };

  const handleRemoveFaqs = (index) => {
    const updatedData = [...faqsDetailsData];
    if (updatedData[index].data.length > 0) {
      updatedData[index].data.pop();
      setFaqsDetailsData(updatedData);
    }
  };

  const updateFaqsData = (groupIndex, faqIndex, key, value) => {
    const updatedData = [...faqsDetailsData];
    updatedData[groupIndex].data[faqIndex] = {
      ...updatedData[groupIndex].data[faqIndex],
      [key]: value,
    };
    setFaqsDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[200px] flex justify-around">
            FAQs
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {faqsDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {faqsDetailsData.map((faqGroup, groupIndex) => (
        <div key={groupIndex} style={{ visibility: hide ? 'hidden' : 'visible', height: hide ? '0' : 'auto' }} className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'>
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex+1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={faqGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...faqsDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setFaqsDetailsData(updatedData);
                }}
              />
            </div>

            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={faqGroup.description}
                onChange={(e) => {
                  const updatedData = [...faqsDetailsData];
                  updatedData[groupIndex].description = e.target.value;
                  setFaqsDetailsData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={faqGroup.position}
                  onChange={(e) => {
                    const updatedData = [...faqsDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setFaqsDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={faqGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...faqsDetailsData];
                        updatedData[groupIndex].publish = e.target.value === 'true';
                        setFaqsDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Faqs Data
                <AddCircleIcon
                  onClick={() => handleAddFaqs(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveFaqs(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />

              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {faqGroup.data.map((faq, faqIndex) => (
                <div key={faqIndex}>
                  <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto">
                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Question
                      </label>
                      <RichTextEditor
                        value={faq.question}
                        onChange={(e) =>
                          updateFaqsData(groupIndex, faqIndex, "question", e.target.value)
                        }
                      />
                    </div>
                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Answer
                      </label>
                      <RichTextEditor
                        value={faq.answer}
                        onChange={(e) =>
                          updateFaqsData(groupIndex, faqIndex, "answer", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto">
                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Position
                      </label>
                      <input
                        type="number"
                        value={faq.position}
                        onChange={(e) =>
                          updateFaqsData(groupIndex, faqIndex, "position", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-36      "
                      />
                    </div>
                  </div>
                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default Faqs;