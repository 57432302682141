import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import CollaborationsDatatable from "../../components/datatables/CollaborationsDatatable";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";

const AllCollaborations = () => {
  const [collaborationsData, setCollaborationsData] = useState(null);
  console.log(collaborationsData);

  useEffect(() => {
    getCollaborationDetails();
  }, []);

  const getCollaborationDetails = async () => {
    try {
      let page = 1;
      let limit = 100;
      const response = await Services.getAllCollaborations(page, limit);
      // console.log(response.data);
      setCollaborationsData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error!! from getCollaborationDetails",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  return (
    <div className="single">
      <Sidebar />

      <div className="singleContainer">
        <Navbar />
        {collaborationsData ? (
          <CollaborationsDatatable collaborations={collaborationsData} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default AllCollaborations;
