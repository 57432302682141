import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";
import CollaborationTypeDatatable from "../../components/datatables/CollaborationTypeDatatable";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const CollaborationType = () => {
    const [typesData, setTypesData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [type, setType] = useState({
        id: "",
        type: "",
    });
    const [error, setError] = useState(false);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getCollaborationType();
    }, [open]);

    const updateTagOpen = (id, type) => {
        setError(false);
        setType({
            id,
            type,
        });
        setOpen(true);
    };

    const addTagOpen = () => {
        setError(false);
        setType({
            id: "",
            type: "",
        });
        setOpen(true);
    };

    const getCollaborationType = async () => {
        try {
            const response = await Services.getAllCollaborationTypes();
            setTypesData(response.data);
            toast.success("Event types loaded successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error(
                error.response.data.message ? error.response.data.message : "Error!!",
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    };

    const addCollaborationType = async (tag) => {
        if (tag.trim() === "") {
            return;
        }
        try {
            const response = await Services.addCollaborationType({ type: tag.trim() });
            handleClose();
            if (response.status === 200) {
                toast.success("Event type added successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

        } catch (error) {
            toast.error(
                error.response.data.message ? error.response.data.message : "Error!!",
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    };

    const updateCollaborationType = async (id, tag) => {
        if (tag.trim() === "") {
            return;
        }
        try {
            const response = await Services.updateCollaborationType(id, { type: tag.trim() });
            handleClose();
            if (response.status === 200) {
                toast.success("Event type updated successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            toast.error(
                error.response.data.message ? error.response.data.message : "Error!!",
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    };

    const deleteCollaboration = async (id) => {
        if (!id) {
            toast.error("Invalid event type ID.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        if (!window.confirm("Are you sure you want to delete this event type?")) {
            toast.info("Deletion cancelled.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        try {
            const response = await Services.deleteCollaborationType(id);
            handleClose();
            if (response.status === 200) {
                toast.success("Event type deleted successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            // Refresh the data after deletion without reloading the whole page
            await getCollaborationType();
        } catch (error) {
            toast.error(
                error.response && error.response.data.message
                    ? error.response.data.message
                    : "Error in deletion!",
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    };

    return (
        <div className="page">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                {typesData ? (
                    <CollaborationTypeDatatable
                        types={typesData}
                        updateTagOpen={updateTagOpen}
                        addTagOpen={addTagOpen}
                        deleteCollaboration={deleteCollaboration}
                    />
                ) : (
                    <Loader />
                )}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {type.id ? "Update Type " + type.id : "Add Type"}
                        </Typography>
                        <OutlinedInput
                            value={type.type}
                            name="type"
                            error={error}
                            required={true}
                            type="text"
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setError(true);
                                } else if (error) {
                                    setError(false);
                                }
                                setType((prevValue) => {
                                    return { ...prevValue, [e.target.name]: e.target.value };
                                });
                            }}
                        />
                        <Button
                            style={{ marginLeft: "20px" }}
                            onClick={() =>
                                type.id
                                    ? updateCollaborationType(type.id, type.type)
                                    : addCollaborationType(type.type)
                            }
                        >
                            {type.id ? "Update" : "Add"}
                        </Button>
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default CollaborationType;
