// import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import Sidebar from "../../../components/sidebar/Sidebar";
import { Api } from "../../../helper";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ViewIncubationForm = () => {
  const { incubationId } = useParams();
  const [openSection, setOpenSection] = useState(null);
  const [incubationData, setincubationData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.get(
          `/incubator/register-form?id=${incubationId}`,
          "multipart/form-data"
        );

        if (response.data && response.data.data) {
          setincubationData(response.data.data);
          toast.success("Incubation Details fetched successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("Error fetching contact data:", error);
      }
    };

    fetchData();
  }, [incubationId]);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const faqData = [
    {
      title: "Company / Startup name",
      content: incubationData.companyName,
    },
    {
      title: "Startup Email",
      content: incubationData.startupEmail,
    },
    {
      title: "What is your problem statement?",
      content: incubationData.problemStatement,
    },
    {
      title:
        "Which industryVertical do you belong to from the healthcare industry?",
      content: incubationData.industryVertical,
    },
    {
      title:
        "Where is the company operating from and where is it incorporated?",
      content: incubationData.operatingLocation,
    },
    {
      title:
        "Mobile number of the founder who is filling out this application.",
      content: incubationData.founderMobile,
    },
    {
      title: "Upload your pitch deck",
      content: incubationData.pitchDeck,
    },
    {
      title:
        "Is the founding team working full time or part time on this idea/startup?",
      content: incubationData.teamEngagement,
    },
    {
      title: "Are people using your product?",
      content: incubationData.usingProduct,
    },
    {
      title: "Do you have revenue?",
      content: incubationData.revenue,
    },
    {
      title: "Have you formed ANY legal entity yet?",
      content: incubationData.legalEntityStatus,
    },
    {
      title: "Have you taken any investmentReceived yet?",
      content: incubationData.investmentReceived,
    },
    {
      title: "Are you currently fundraising?",
      content: incubationData.fundraising,
    },
    {
      title: "How did you hear about VOH Incubator?",
      content: incubationData.vohSource,
    },
    {
      title: "How far along are you? (In terms of idea execution)",
      content: incubationData.executionStage,
    },
    {
      title: "What's your Name (Applicant Name)",
      content: incubationData.applicantName,
    },
    {
      title: "Website link if you have",
      content: incubationData.websiteLink,
    },
    {
      title: "Details of founder/cofounder",
      content: incubationData.founderDetails,
    },
    {
      title:
        "Who are your competitorsInfo? What do you understand about your business that they don't?",
      content: incubationData.competitorsInfo,
    },
    {
      title: "Vision of your Product and Services",
      content: incubationData.vision,
    },
    {
      title:
        "Problem that the product or service intends to solve/help to solve.",
      content: incubationData.problemToSolve,
    },
  ];

  return (
    <div className="bg-white page">
      <Sidebar />
      <div className="p-4 listContainer">
        <div className="bg-white p-5 rounded-lg w-full h-[100%]">
          <div className="border-b border-gray-900/10 overflow-y-auto h-[100%]">
            <Link
              to={`/incubator/registration`}
              style={{ textDecoration: "none" }}
            >
              <button className="text-white bg-[#3ea2d2] from-blue-500 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 ml-[95%]">
                <AiOutlineClose />
              </button>
            </Link>
            {faqData.map((item, index) => (
              <div
                key={index}
                className={`rounded-xl text-xl font-bold mb-4 m-auto  ${
                  openSection === index
                    ? "bg-[#3fa2d1] text-white border-none"
                    : " text-black bg-gray-200"
                }`}
              >
                <button
                  onClick={() => toggleSection(index)}
                  className="faq-question flex justify-between items-center w-full text-left py-2 px-4  focus:outline-none focus:bg-[#3fa2d1] rounded-xl"
                >
                  <span>{item.title}</span>
                  <MdKeyboardArrowRight
                    className={`transition-transform duration-200 ${
                      openSection === index ? "transform rotate-90" : ""
                    }`}
                    size="30"
                  />
                </button>
                {openSection === index && (




                typeof item.content === "boolean" ? (
                  <p
                    className={
                      "faq-answer text-md py-2 px-4 font-normal bg-[#3fa2d1] rounded-b-xl"
                    }
                  >
                    {item.content ? "Yes" : "No"}
                  </p>
                ) : (
                  <p
                    className={
                      "faq-answer text-md py-2 px-4 font-normal bg-[#3fa2d1] rounded-b-xl"
                    }
                  >
                    {item.content}
                  </p>
                )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewIncubationForm;
