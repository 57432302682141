// import { useState } from "react";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import { Typography } from "@mui/material";
// import RichTextEditor from "../Event/RichEditor";
// // import Image from "next/image";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// function WhyParticipate({
//   whyParticipateDetailsData,
//   setWhyParticipateDetailsData,
// }) {
//   const addData = () => {
//     setWhyParticipateDetailsData({
//       groupHeading: "Why to Participate?",
//       description: "",
//       position: 6,
//       publish: false,
//       data: [],
//     });
//   };

//   const removeData = () => {
//     setWhyParticipateDetailsData(null);
//   };

//   const handleAddParticipate = () => {
//     if (whyParticipateDetailsData) {
//       const updatedData = { ...whyParticipateDetailsData };
//       updatedData.data.push({ points: "",
//         icon:"",
//        });
//       setWhyParticipateDetailsData(updatedData);
//     }
//   };

//   const handleRemoveParticipate = (index) => {
//     if (
//       whyParticipateDetailsData &&
//       whyParticipateDetailsData.data.length > 0
//     ) {
//       const updatedData = { ...whyParticipateDetailsData };
//       updatedData.data.splice(index, 1);
//       setWhyParticipateDetailsData(updatedData);
//     }
//   };

//   const updateParticipatePoint = (index,key, value) => {
//     setWhyParticipateDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item,[key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");

//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[250px] flex justify-around">
//             Why Participate
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {whyParticipateDetailsData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {whyParticipateDetailsData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"groupHeading"}
//                 value={whyParticipateDetailsData.groupHeading}
//                 onChange={(e) => {
//                   setWhyParticipateDetailsData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Description
//               </label>
//               <RichTextEditor
//                 name={"description"}
//                 value={whyParticipateDetailsData.description}
//                 onChange={(e) => {
//                   setWhyParticipateDetailsData((prev) => ({
//                     ...prev,
//                     description: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={whyParticipateDetailsData.position}
//                   onChange={(e) => {
//                     setWhyParticipateDetailsData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={whyParticipateDetailsData.publish}
//                       onChange={(e) => {
//                         setWhyParticipateDetailsData((prev) => ({
//                           ...prev,
//                           publish: e.target.value === "true",
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>

//             <div>
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Participate Data
//                 <AddCircleIcon
//                   onClick={() => handleAddParticipate()}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemoveParticipate()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               {whyParticipateDetailsData.data.map((item, index) => (
//                 <div key={index}>
//                   <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto">
//                     <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                       Points
//                     </label>
//                     <RichTextEditor
//                       value={item.points}
//                       onChange={(e) =>
//                         updateParticipatePoint(index, "points",e.target.value)
//                       }
//                     />
//                   </div>
//                   <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto">
//                     <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                       Icon
//                     </label>
//                     <input
//                           type="file"
//                           accept="image/*"
//                           onChange={(e) => {
//                             const file = e.target.files[0];
//                             const reader = new FileReader();
//                             reader.onloadend = () => {
//                               updateParticipatePoint(index, "icon", file);
//                             };
//                             if (file) {
//                               reader.readAsDataURL(file);
//                             }
//                           }}
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         />
//                       <img
//                         className="max-w-[100px] max-h-[200px] object-cover rounded-lg mr-4"
//                         src={whyParticipateDetailsData.data.icon}
//                         alt="Image_Uploaded"

//                       />
//                   </div>

//                   <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                 </div>
//               ))}
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default WhyParticipate;


import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Typography } from "@mui/material";
import RichTextEditor from "../Event/RichEditor";
import { MdDelete } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";

function WhyParticipate({ whyParticipateDetailsData, setWhyParticipateDetailsData }) {
  const addData = () => {
    setWhyParticipateDetailsData([
      ...whyParticipateDetailsData,
      {
        groupHeading: "Why to Participate?",
        description: "",
        position: 6,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Why Participate group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (whyParticipateDetailsData.length > 0) {
      const updatedData = [...whyParticipateDetailsData];
      updatedData.pop();
      setWhyParticipateDetailsData(updatedData);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setWhyParticipateDetailsData(whyParticipateDetailsData.filter((_, i) => i !== index));
      toast.success("Why Participate group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  }

  const handleAddParticipate = (index) => {
    const updatedData = [...whyParticipateDetailsData];
    updatedData[index].data.push({ points: "", icon: "" });
    setWhyParticipateDetailsData(updatedData);
  };

  const handleRemoveParticipate = (groupIndex) => {
    const updatedData = [...whyParticipateDetailsData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      setWhyParticipateDetailsData(updatedData);
    }
  };

  const updateParticipatePoint = (groupIndex, pointIndex, key, value) => {
    const updatedData = [...whyParticipateDetailsData];
    updatedData[groupIndex].data[pointIndex] = {
      ...updatedData[groupIndex].data[pointIndex],
      [key]: value,
    };
    setWhyParticipateDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[250px] flex justify-around">
            Why Participate
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {whyParticipateDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {whyParticipateDetailsData.map((participateGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className="mt-1 border-2 border-solid border-[#489FC5] rounded-md"
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-2xl text-[#489FC5] mt-2 ml-2">{`No: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px",
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12 mb-12">
              <label htmlFor="" className="mb-6 text-xl font-medium text-[#489FC5]">
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={participateGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...whyParticipateDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setWhyParticipateDetailsData(updatedData);
                }}
              />
            </div>

            <div className="mx-auto w-11/12 mb-12">
              <label htmlFor="" className="mb-6 text-xl font-medium text-[#489FC5]">
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={participateGroup.description}
                onChange={(e) => {
                  const updatedData = [...whyParticipateDetailsData];
                  updatedData[groupIndex].description = e.target.value;
                  setWhyParticipateDetailsData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={participateGroup.position}
                  onChange={(e) => {
                    const updatedData = [...whyParticipateDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setWhyParticipateDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
              <div className="flex-1">
                <div className="">
                  <label className="w-8/12 gap-8 ml-16 text-xl font-medium text-[#489FC5]">
                    Publish :
                    <select
                      value={participateGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...whyParticipateDetailsData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        setWhyParticipateDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Participate Data
                <AddCircleIcon
                  onClick={() => handleAddParticipate(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveParticipate(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {participateGroup.data.map((item, pointIndex) => (
                <div key={pointIndex}>
                  <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Points
                    </label>
                    <RichTextEditor
                      value={item.points}
                      onChange={(e) =>
                        updateParticipatePoint(groupIndex, pointIndex, "points", e.target.value)
                      }
                    />
                  </div>
                  <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Icon
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          updateParticipatePoint(groupIndex, pointIndex, "icon", file);
                        };
                        if (file) {
                          reader.readAsDataURL(file);
                        }
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-3 w-full"
                    />
                    {item.icon && (
                      <img
                        className="max-w-[50px] max-h-[50px] object-cover rounded-lg mr-4"
                        src={item.icon}
                        alt="Image_Uploaded"
                      />
                    )}
                  </div>
                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default WhyParticipate;
