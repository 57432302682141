import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import EventDatatable from "../../components/datatables/EventDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import { toast } from "react-toastify";

const EventsPage = () => {
  const [eventsData, setEventsData] = useState(null);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      const response = await Services.getAllEvents();
      setEventsData(response.data);
      console.log(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {eventsData ? <EventDatatable events={eventsData} /> : <Loader />}
      </div>
    </div>
  );
};

export default EventsPage;
