import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import RichTextEditor from "../Event/RichEditor";



const UpdateEditorialPage = ({ inputs, title }) => {
  const { editorialId } = useParams();
  const navigate = useNavigate();

  const [editorialData, setEditorialData] = useState({
    editorialType: "default",
    headLine: "",
    subHeadLine: "",
    date: "",
    description: "",
    squareImage: "",
    mediaLink: "",
    tags: "",
    publish: false,
    showBelowEditorialType: "default",
    para: ""
  });

  const [editorialTypes, setEditorialTypes] = useState(null);

  useEffect(() => {
    getEditorial();
    getAllEditorialTypes();
  }, []);

  const getAllEditorialTypes = async () => {
    try {
      const response = await Services.getAllEditorialTypes();
      setEditorialTypes(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  const getEditorial = async () => {
    try {
      const response = await Services.getEditorial(editorialId);
      const tags = response.data.tags.join();
      var date = "";
      if (response.data.date) {
        date = response.data.date.split("T")[0];
      }
      setEditorialData(prev => {
        return {
          ...prev,
          ...response.data,
          tags: tags,
          showBelowEditorialType: response.data.editorialType._id,
          editorialType: response.data.editorialType._id,
          date: date
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (editorialData.editorialType === "default") {
      toast.error("Please select editorial type", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var date = null;

    if (editorialData.date) {
      date = new Date(editorialData.date).toISOString();
    }

    const formData = new FormData();

    for (let key of Object.keys(editorialData)) {
      formData.set(key, editorialData[key]);
    }

    formData.set("date", date);
    formData.set("tags", editorialData.tags.split(","))


    try {
      const response = await Services.updateEditorial(editorialId, formData);
      toast.success("Editorial Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate(`/editorials/${response.data.editorialId}`);
    } catch (error) {
      if (error?.response?.data?.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      return setEditorialData((prevValue) => {
        return {
          ...prevValue,
          [name]: files[0],
        };
      });
    }

    setEditorialData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="px-8 py-4">
          <div className="py-4">
            <h1 className="text-3xl font-bold py-2 mb-6 text-gray-800 w-full bg-slate-200 text-center">{title}</h1>
          </div>
          {editorialTypes ? (
            <div className="bg-white rounded-lg shadow-xl p-6">
              <form onSubmit={onSubmit} className="space-y-6 max-w-3xl mx-auto">
                {inputs.map((input) => (
                  <div key={input.id} className={`space-y-2 ${input.name === 'editorialType' || input.name === 'date' || input.name === 'squareImage' || input.name === 'mediaLink' || input.name === 'tags' || input.name === 'publish' ? 'w-[48%] inline-block' : 'w-[100%]'}`}>
                    <label className="block text-sm font-medium text-gray-700">
                      {input.label}
                      {input.required && <span className="text-red-500">*</span>}
                    </label>
                    {input.name === "description" ? (
                      <RichTextEditor name={input.name} onChange={handleChange} value={editorialData[input.name]} className="w-full" />
                    ) : input.name === "editorialType" || input.name === "showBelowEditorialType" ? (
                      <select
                        value={editorialData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                          className="mt-1 block  w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {editorialTypes.map((type) => {
                          return (
                            <option key={type._id} value={type._id}>
                              {type.type}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.name === "publish" ? (
                      <select
                        value={editorialData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                        className="mt-1 block w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    )
                      : (
                        input.type === "quill" ? <RichTextEditor name={input.name} onChange={handleChange} value={editorialData[input.name]} /> : (
                          input.type === "file" ? <input
                            type={input.type}
                            name={input.name}
                            placeholder={input.placeholder}
                            onChange={handleChange}
                            className="mt-1 block w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          /> :
                            <input
                              type={input.type}
                              name={input.name}
                              value={editorialData[input.name]}
                              placeholder={input.placeholder}
                              onChange={handleChange}
                              className="mt-1 block w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                        )
                      )
                    }
                  </div>
                ))}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Paragraphs</label>
                  <RichTextEditor
                    name="para"
                    onChange={handleChange}
                    value={editorialData.para}
                    className="w-full"
                  />
                </div>
                <div className="flex justify-center">
                  <button className="w-auto px-6 bg-[#479fc5] text-white py-2 rounded-md hover:bg-[#028fa1] focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-out">
                    Update Editorial
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateEditorialPage;
