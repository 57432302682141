import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import UserTypeDatatable from "../../components/datatables/UserTypeDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserTypesPage = () => {
  const [userTypesData, setUserTypesData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [userType, setUserType] = useState({
    id: "",
    userType: "",
  });
  const [error, setError] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getUserTypes();
  }, [open]);

  const updateUserTypeOpen = (id, userType) => {
    setError(false);
    setUserType({
      id,
      userType,
    });
    setOpen(true);
  };

  const addUserTypeOpen = () => {
    setError(false);
    setUserType({
      id: "",
      userType: "",
    });
    setOpen(true);
  };

  const getUserTypes = async () => {
    try {
      const response = await Services.getAllUserTypes();
      setUserTypesData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const addUserType = async (userType) => {
    if (userType.trim() === "") {
      return;
    }
    try {
      const response = await Services.addUserType({
        userType: userType.trim(),
      });
      if (response.status === 200) {
        toast.success("User type added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      handleClose();
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const updateUserType = async (id, userType) => {
    if (userType.trim() === "") {
      return;
    }
    try {
      const response = await Services.updateUserType(id, {
        userType: userType.trim(),
      });
      if (response.status === 200) {
        toast.success("Updated user type successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      handleClose();
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const deleteUserType = async (id) => {
    if (!id) {
      toast.error("Invalid user type ID.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!window.confirm("Are you sure you want to delete this user type?")) {
      toast.info("Deletion cancelled.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    try {
      const response = await Services.deleteUserType(id);
      handleClose();
      if (response.status === 200) {
        toast.success("User type deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Refresh the data after deletion without reloading the whole page
      await getUserTypes();
    } catch (error) {
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error in deletion!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {userTypesData ? (
          <UserTypeDatatable
            userTypes={userTypesData}
            updateUserTypeOpen={updateUserTypeOpen}
            addUserTypeOpen={addUserTypeOpen}
            deleteUserType={deleteUserType}
          />
        ) : (
          <Loader />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {userType.id
                ? "Update User Type " + userType.id
                : "Add User Type"}
            </Typography>
            <OutlinedInput
              value={userType.userType}
              name="userType"
              error={error}
              required={true}
              type="text"
              onChange={(e) => {
                if (e.target.value === "") {
                  setError(true);
                } else if (error) {
                  setError(false);
                }
                setUserType((prevValue) => {
                  return { ...prevValue, [e.target.name]: e.target.value };
                });
              }}
            />
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() =>
                userType.id
                  ? updateUserType(userType.id, userType.userType)
                  : addUserType(userType.userType)
              }
            >
              {userType.id ? "Update" : "Add"}
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default UserTypesPage;
