import "./navbar.scss";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
          <Link to="/admin" state={{ userId:user.userId }} style={{ textDecoration: "none" }}>
          <div className="item">
            <AccountCircleOutlinedIcon style={{width: "40px", height: "40px", color: "white"}} className="icon" />
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
