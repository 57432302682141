import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { Api } from "../../helper";

const Gallery = ({ handleComponentChange }) => {
  const [images, setImages] = useState([]);
  const [newImageUrl, setNewImageUrl] = useState("");
  const [editImageUrl, setEditImageUrl] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Api.get("/incubator/gallery");
      if (response.data && response.data.data) {
        setImages(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await Api.delete(`/incubator/gallery?id=${id}`);
      if (response.status === 200) {
        fetchData(); // Fetch data again after deletion
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleAddImage = async () => {
    try {
      const formData = new FormData();
      formData.append("image", newImageUrl);

      const response = await Api.post("/incubator/gallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        fetchData(); // Fetch data again after adding new image
      }
    } catch (error) {
      console.error("Error adding image:", error);
    }
  };

  const handleEditImage = async (id) => {
    try {
      const formData = new FormData();
      formData.append("image", editImageUrl);

      const response = await Api.put(`/incubator/gallery?id=${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        fetchData(); // Fetch data again after updating image
      }
    } catch (error) {
      console.error("Error updating image:", error);
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="flex flex-1 listContainer p-4">
        <div className="ml-5">
          <h1 className="text-3xl font-bold mb-4 text-[#3ea2d2]">
            Image Gallery
          </h1>

          {/* Add Image Form */}
          <div className="mb-4">
            <input
              type="file"
              onChange={(e) => setNewImageUrl(e.target.files[0])}
              className="px-4 py-2 border rounded w-full"
            />
            <button
              onClick={handleAddImage}
              className="bg-green-500 text-white px-4 py-2 rounded mt-2 "
            >
              Add Image
            </button>
          </div>

          {/* Image Gallery */}
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {images.map((image) => (
              <div
                key={image._id}
                className="flex flex-col items-center border p-4 rounded-lg"
              >
             
                <img
                  src={image.image}
                  alt={image._id}
                  className="w-full h-auto mb-2"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
                <input
                  type="file"
                  onChange={(e) => setEditImageUrl(e.target.files[0])}
                  className="px-4 py-2 border rounded w-full"
                />
                <div className="flex mt-2 gap-2">
                  <button
                    onClick={() => handleEditImage(image._id)}
                    className="bg-green-500 text-white px-4 py-2 rounded w-full"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => handleDeleteImage(image._id)}
                    className="bg-red-500 text-white px-4 py-2 rounded w-full"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
