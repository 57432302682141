import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { eventModeColumns } from "../../datatablesource";
import Box from "@mui/material/Box";
import { adminType } from "../../constants";

function QuickSearchToolbar() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      {user.adminType === adminType.superAdmin && (
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      )}
    </Box>
  );
}

const EventModeDatatable = ({
  modes,
  updateModeOpen,
  addModeOpen,
  deleteEventMode,
}) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      disableExport: true,
      renderCell: (params) => {
        console.log(params.row);
        return (
          <div className="cellAction">
            <div
              onClick={() => updateModeOpen(params.row._id, params.row.mode)}
              className="updateButton"
            >
              Update
            </div>
            {/* <div
              onClick={() => deleteEventMode(params.row._id)}
              className="deleteButton"
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Event Modes
        <div onClick={addModeOpen} className="link">
          Add New
        </div>
      </div>
      <DataGrid
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        getRowId={(row) => row._id}
        className="datagrid"
        rows={modes}
        columns={eventModeColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "scroll",
          },
        }}
      />
    </div>
  );
};

export default EventModeDatatable;
