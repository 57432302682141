import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";

const ViewDetails = () => {
  const { collaborationId } = useParams();
  const [collaborationData, setCollaborationData] = useState(null);

  const getCollaborationDetails = useCallback(async () => {
    try {
      const response = await Services.getCollaborationById(collaborationId);
      setCollaborationData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error!! from getCollaborationDetails",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [collaborationId]); // Memoize the getCollaborationDetails function

  useEffect(() => {
    getCollaborationDetails();
  }, [getCollaborationDetails]);
  
  return (
    <div>
      <div className="single">
        <Sidebar />
        {collaborationData ? (
          <div className="singleContainer">
            <Navbar />
            <div className="top">
              <div className="left">
                <Link
                  to={`/collaborations/update/${collaborationId}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="editButton">Edit</div>
                </Link>
                <h1 className="title">Information</h1>
                <div className="item">
                  <div className="details">
                    {/* <h1 className="itemTitle">{collaborationData.name}</h1> */}
                    <div className="detailItem">
                      <span className="itemKey">Name</span>
                      <span className="itemValue">
                        {collaborationData.name}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Email Id:</span>
                      <span className="itemValue">
                        {collaborationData.email}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Mobile:</span>
                      <span className="itemValue">
                        {collaborationData.mobileNo}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Designation:</span>
                      <span className="itemValue">
                        {collaborationData.designation}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Company Name:</span>
                      <span className="itemValue">
                        {collaborationData.companyName}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Company Address:</span>
                      <span className="itemValue">
                        {collaborationData.companyAddress}
                      </span>
                    </div>
                    {/* <div className="detailItem">
                      <span className="itemKey">Collaborate Interest</span>
                      <span className="itemValue">
                        {collaborationData.collaborationTypes}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ViewDetails;
