// import { useState } from "react";
// import RichTextEditor from "../Event/RichEditor";
// import { Typography } from "@mui/material";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// import { MdDelete } from "react-icons/md";

// function KeyTracks({ keyTracksData, SetKeyTracksData }) {
//   // console.log(keyTracksData);
//   const addData = () => {
//     // SetKeyTracksData({
//     //   groupHeading: "In association with",
//     //   description: "",
//     //   position: 1,
//     //   publish: false,
//     //   data: [],
//     // });
//     SetKeyTracksData([...keyTracksData, {
//       groupHeading: "In association with",
//       description: "",
//       position: 1,
//       publish: false,
//       data: [],
//     }]);
//   };

//   const removeData = (index) => {
//     if (window.confirm("Are you sure you want to delete this section?")) {
//       SetKeyTracksData(keyTracksData.filter((_, i) => i !== index));
//     }
//   };

//   const handleAddKeyTrack = (index) => {
//     const updatedData = [...keyTracksData];
//     updatedData[index].data.push({
//       number: "",
//       notes: "",
//     });
//     SetKeyTracksData(updatedData);
//   };

//   const handleRemoveKeyTrack = (index) => {
//     const updatedData = [...keyTracksData];
//     if (updatedData[index].data.length > 0) {
//       updatedData[index].data.pop();
//       SetKeyTracksData(updatedData);
//     }
//   };

//   const updateKeyTrackData = (groupIndex, faqIndex, key, value) => {
//     const updatedData = [...keyTracksData];
//     updatedData[groupIndex].data[faqIndex] = {
//       ...updatedData[groupIndex].data[faqIndex],
//       [key]: value,
//     };
//     SetKeyTracksData(updatedData);
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");

//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[200px] flex justify-around">
//             Key Track
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           <AddIcon
//             onClick={() => addData()}
//             style={{
//               fontSize: "48px",
//               margin: "0 0 0 40px",
//               cursor: "pointer",
//             }}
//           />
//         </h1>
//       </div>
//       {keyTracksData.map((faqGroup, groupIndex) => (
//         <div key={groupIndex}
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <div className="flex justify-end mr-8 text-red-600">
//             <span className="mr-2 text-3xl text-[#489FC5]">{`${groupIndex + 1}`}</span>
//             <MdDelete
//               onClick={() => removeData(groupIndex)}
//               style={{
//                 fontSize: "35px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           </div>
//           <form action="">
//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"groupHeading"}
//                 value={faqGroup.groupHeading}
//                 onChange={(e) => {
//                   const updatedData = [...keyTracksData];
//                   updatedData[groupIndex].groupHeading = e.target.value;
//                   SetKeyTracksData(updatedData);
//                 }}
//               />
//             </div>
//             <div className="mx-auto w-11/12  mb-12">
//               <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                 Description
//               </label>
//               <RichTextEditor
//                 name={"description"}
//                 value={faqGroup.description}
//                 onChange={(e) => {
//                   const updatedData = [...keyTracksData];
//                   updatedData[groupIndex].description = e.target.value;
//                   SetKeyTracksData(updatedData);
//                 }}
//                 className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//               />
//             </div>
//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={faqGroup.position}
//                   onChange={(e) => {
//                     const updatedData = [...keyTracksData];
//                     updatedData[groupIndex].position = e.target.value;
//                     SetKeyTracksData(updatedData);
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={faqGroup.publish}
//                       onChange={(e) => {
//                         const updatedData = [...keyTracksData];
//                         updatedData[groupIndex].publish = e.target.value === 'true';
//                         SetKeyTracksData(updatedData);
//                       }}
//                     >
//                       <option value="false">False</option>
//                       <option value="true">True</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>

//             <div className="w-10/12">
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Key Tracks Data
//                 <AddCircleIcon
//                   onClick={() => handleAddKeyTrack(groupIndex)}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemoveKeyTrack(groupIndex)}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 mr-4 border-[2px] border-[#489FC5] mx-auto  my-10"></div>
//               {/* {keyTracksData.data &&
//                 keyTracksData.data.map((track, index) => ( */}
//               {keyTracksData.data.map((track, trackIndex) => (
//                   <div>
//                     <div className=" flex w-11/12 gap-8 ml-16 my-6 mb-10">
//                       <div className="flex-1">
//                         <label className=" mb-6    text-xl  font-medium text-[#489FC5]">
//                           Notes
//                         </label>
//                         <RichTextEditor
//                           name={"notes"}
//                           value={track.notes}
//                           onChange={(e) =>
//                             updateKeyTrackData(groupIndex, trackIndex, "notes", e.target.value)
//                           }
//                         />
//                       </div>
//                     </div>
//                     <div className=" flex w-11/12 gap-8 ml-16 my-6 mb-10">
//                       <div className="flex-1">
//                         <label className=" mb-6    text-xl  font-medium text-[#489FC5]">
//                           Number
//                         </label>
//                         <input
//                           type="text"
//                           value={track.number }
//                           onChange={(e) =>
//                             updateKeyTrackData(groupIndex, trackIndex, "number", e.target.value)
//                           }
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full"
//                         />
//                       </div>
//                     </div>
//                     <div className="h-0 w-11/12 mr-4 border-[2px] border-[#489FC5] mx-auto  my-10"></div>
//                   </div>
//                 ))}
//             </div>
//           </form>
//         </div>
//       ))}
//     </>
//   );
// }
// export default KeyTracks;


import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { MdDelete } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";

function KeyTracks({ keyTracksData, SetKeyTracksData }) {
  const addData = () => {
    SetKeyTracksData([...keyTracksData, {
      groupHeading: "In association with",
      description: "",
      position: 1,
      publish: false,
      data: [],
    }]);
    toast.success("New Key Track group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (keyTracksData.length > 0) {
      const updatedData = [...keyTracksData];
      updatedData.pop();
      SetKeyTracksData(updatedData);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      SetKeyTracksData(keyTracksData.filter((_, i) => i !== index));
      toast.success("Key Track group deleted successfully!", {
        
        position: toast.POSITION.TOP_RIGHT,
        
      });
    }
  };

  const handleAddKeyTrack = (index) => {
    const updatedData = [...keyTracksData];
    updatedData[index].data.push({
      number: "",
      notes: "",
    });
    SetKeyTracksData(updatedData);
  };

  const handleRemoveKeyTrack = (index) => {
    const updatedData = [...keyTracksData];
    if (updatedData[index].data.length > 0) {
      updatedData[index].data.pop();
      SetKeyTracksData(updatedData);
    }
  };

  const updateKeyTrackData = (groupIndex, trackIndex, key, value) => {
    const updatedData = [...keyTracksData];
    updatedData[groupIndex].data[trackIndex] = {
      ...updatedData[groupIndex].data[trackIndex],
      [key]: value,
    };
    SetKeyTracksData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[200px] flex justify-around">
            Key Track
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {keyTracksData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>

        </h1>
      </div>
      {keyTracksData.map((keyTrackGroup, groupIndex) => (
        <div key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'
        >

          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={keyTrackGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...keyTracksData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  SetKeyTracksData(updatedData);
                }}
              />
            </div>
            <div className="mx-auto w-11/12  mb-12">
              <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={keyTrackGroup.description}
                onChange={(e) => {
                  const updatedData = [...keyTracksData];
                  updatedData[groupIndex].description = e.target.value;
                  SetKeyTracksData(updatedData);
                }}
              />
            </div>
            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={keyTrackGroup.position}
                  onChange={(e) => {
                    const updatedData = [...keyTracksData];
                    updatedData[groupIndex].position = e.target.value;
                    SetKeyTracksData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={keyTrackGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...keyTracksData];
                        updatedData[groupIndex].publish = e.target.value === 'true';
                        SetKeyTracksData(updatedData);
                      }}
                    >
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-10/12">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Key Tracks Data
                <AddCircleIcon
                  onClick={() => handleAddKeyTrack(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveKeyTrack(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 mr-4 border-[2px] border-[#489FC5] mx-auto  my-10"></div>
              {keyTrackGroup.data.map((track, trackIndex) => (
                <div key={trackIndex}>
                  <div className=" flex w-11/12 gap-8 ml-16 my-6 mb-10">
                    <div className="flex-1">
                      <label className=" mb-6    text-xl  font-medium text-[#489FC5]">
                        Notes
                      </label>
                      <RichTextEditor
                        name={"notes"}
                        value={track.notes}
                        onChange={(e) =>
                          updateKeyTrackData(groupIndex, trackIndex, "notes", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className=" flex w-11/12 gap-8 ml-16 my-6 mb-10">
                    <div className="flex-1">
                      <label className=" mb-6    text-xl  font-medium text-[#489FC5]">
                        Number
                      </label>
                      <input
                        type="text"
                        value={track.number}
                        onChange={(e) =>
                          updateKeyTrackData(groupIndex, trackIndex, "number", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full"
                      />
                    </div>
                  </div>
                  <div className="h-0 w-11/12 mr-4 border-[2px] border-[#489FC5] mx-auto  my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}
export default KeyTracks;
