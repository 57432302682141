import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import VohTvDatatable from "../../components/datatables/VohTvDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VohTvsPage = () => {
  const [vohTvsData, setvohTvsData] = useState(null);
  const [deleteVohTvData, setDeleteVohTvData] = useState({ id: "" });
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getVohTvs();
  }, [refresh]);

  const refreshFn = () => {
    setRefresh(!refresh);
  };

  const getVohTvs = async () => {
    try {
      const response = await Services.getAllVohTvs();
      setvohTvsData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const deleteVohTv = async () => {
    try {
      const response = await Services.deleteVohTv(deleteVohTvData.id);
      if (response.status === 200) {
        toast.success(`Successfully deleted ${deleteVohTvData.id}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setDeleteVohTvData({ id: "" });
      handleClose();
      refreshFn();
    } catch (error) {
      toast.error("Something went wrong!!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteVohTvModalOpen = (id) => {
    setDeleteVohTvData({ id });
    handleOpen();
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {vohTvsData ? (
          <VohTvDatatable
            vohtvs={vohTvsData}
            deleteVohTvModalOpen={deleteVohTvModalOpen}
          />
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete Voh Tv?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            id : {deleteVohTvData.id}
          </Typography>
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={deleteVohTv}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default VohTvsPage;
