import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function Venue({ venueDetailsData, setVenueDetailsData }) {
  const addData = () => {
    setVenueDetailsData({
      groupHeading: "Venue",
      venue: "",
      description: "",
      position: 0,
      publish: false,
      latitude: "",
      longitude: "",
      onlineMeetingLink: "",
    });
  };

  const removeData = () => {
    setVenueDetailsData(null);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");
  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[150px] flex justify-between">
            Venue
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          {venueDetailsData === null ? (
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
          ) : (
            <RemoveIcon
              onClick={() => removeData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
          )}
        </h1>
      </div>
      {venueDetailsData !== null && (
        <div
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
        >
          <form action="">
            <div className="mb-4">
              <div className="mb-4">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Group Heading
                </label>
                <RichTextEditor
                  name={"groupHeading"}
                  value={venueDetailsData.groupHeading}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      groupHeading: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Description
                </label>
                <RichTextEditor
                  name={"description"}
                  value={venueDetailsData.description}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Venue
                </label>
                <RichTextEditor 
                  type="text"
                  value={venueDetailsData.venue}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      venue: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>

            </div>


            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Latitude
                </label>
                <input
                  type="text"
                  value={venueDetailsData.latitude}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      latitude: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Longitude
                </label>
                <input
                  type="text"
                  value={venueDetailsData.longitude}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      longitude: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Online Meeting Link
                </label>
                <input
                  type="text"
                  value={venueDetailsData.onlineMeetingLink}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      onlineMeetingLink: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>

              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Publish
                </label>
                <select
                  value={venueDetailsData.publish}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      publish: e.target.value === "true",
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={venueDetailsData.position}
                  onChange={(e) => {
                    setVenueDetailsData((prev) => ({
                      ...prev,
                      position: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Venue;
