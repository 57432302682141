import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { Api } from "../../helper";
import RichTextEditor from "../Event/RichEditor";
import { toast } from "react-toastify";

const AboutUs = () => {
  const [formData, setFormData] = useState({
    _id: "",
    heading: "",
    description: "",
    image: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [aboutUsExist, setaboutUsExist] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.get("/incubator/aboutUs");
        if (response.data.data.length > 0) {
          const { heading, description, image, _id } = response.data.data[0];
          setFormData({ heading, description, image, _id });
          setaboutUsExist(true);
          toast.success("About us fetched successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError("Failed to fetch data");
      }
    };

    fetchData(); // Fetch data when component mounts
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleOnChangeImg = (name, files) => {
    console.log(files);
    if (files) {
      setFormData((prevData) => ({
        ...prevData,
        image: files[0],
      }));
    }
  };
  const handleSave = async () => {
    setIsLoading(true);
    let saveResponse;
    try {
      if (aboutUsExist) {
        saveResponse = await Api.put(
          `/incubator/aboutUs?id=${formData._id}`,
          formData,
          "multipart/form-data"
        );
      } else {
        saveResponse = await Api.post(
          `/incubator/aboutUs`,
          {
            heading: formData.heading,
            description: formData.description,
            image: formData.image,
          },
          "multipart/form-data"
        );
      }
      // console.log('Data saved successfully');
      const { _id, heading, description, image } = saveResponse.data.data;
      setFormData({ _id: _id, heading, description, image }); // Update formData with saved data
    } catch (error) {
      console.error("Error saving data:", error);
      setError("Failed to save data");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white page">
      <Sidebar />
      <div className="listContainer p-4">
        <h1 className="text-3xl font-bold text-[#3ea2d2] mb-4">About Us</h1>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="mb-4">
          <label
            className="block text-[#3ea2d2] font-bold mb-2"
            htmlFor="heading"
          >
            Heading
          </label>
          <input
            className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-400"
            type="text"
            name="heading"
            value={formData.heading}
            onChange={handleChange}
            placeholder="Heading"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#3ea2d2] font-bold mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <RichTextEditor
            name="description"
            onChange={handleChange}
            value={formData.description}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#3ea2d2] font-bold mb-2"
            htmlFor="image"
          >
            Image URL
          </label>
          <input
            className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-400"
            type="file"
            // name="image"
            // value={formData.image}
            onChange={(e) => handleOnChangeImg("image", e.target.files)}
            placeholder="Image URL"
          />
          <img
            style={{ width: "60px", objectFit: "cover", height: "60px" }}
            src={formData.image}
            alt=""
          />
        </div>
        <button
          className="bg-[#3ea2d2] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleSave}
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
