import "../singlepage.scss";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useCallback, useEffect } from "react";
import { Services } from "../../Services";
import { useState } from "react";

import Loader from "../../components/loader/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const UserPage = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);

  const getUser = useCallback(async () => {
    try {
      const response = await Services.getUser(userId);
      setUserData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error while fetching user details",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [userId, getUser]);


  return (
    <div className="single">
      <Sidebar />
      {userData ? (
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <Link
                to={`/users/update/${userId}`}
                style={{ textDecoration: "none" }}
              >
                <div className="editButton">Edit</div>
              </Link>
              <h1 className="title">Information</h1>
              <div className="item">
                {userData.userPicture ? (
                  <img src={userData.userPicture} alt="" className="itemImg" />
                ) : (
                  <AccountCircleOutlinedIcon className="itemImg" />
                )}
                <div className="details">
                  <h1 className="itemTitle">
                    {userData.salutation && userData.salutation}{" "}
                    {userData.firstName}{" "}
                    {userData.middleName && userData.middleName}{" "}
                    {userData.lastName}
                    {"  "}
                    <span className={`fieldWithStatus ${userData.flag}`}>
                      {userData.flag}
                    </span>
                  </h1>
                  <div className="detailItem">
                    <span className="itemKey">User Id:</span>
                    <span className="itemValue">{userData.userId}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Active User Id:</span>
                    <span className="itemValue">{userData.activeUserId}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Gender:</span>
                    <span className="itemValue">{userData.gender}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">DOB:</span>
                    <span className="itemValue">
                      {userData.dateOfBirth
                        ? new Date(userData.dateOfBirth).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : null}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Age:</span>
                    <span className="itemValue">{userData.age}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue">{userData.emailId}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Phone:</span>
                    <span className="itemValue">{userData.phoneNumber}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Organization:</span>
                    <span className="itemValue">{userData.organization}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Designation:</span>
                    <span className="itemValue">{userData.designation}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Official Email:</span>
                    <span className="itemValue">
                      {userData.officialEmailId}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Personal Email:</span>
                    <span className="itemValue">
                      {userData.personalEmailId}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Mobile Number 1:</span>
                    <span className="itemValue">{userData.mobileNumber1}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Mobile Number 2:</span>
                    <span className="itemValue">{userData.mobileNumber2}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Landline Number 1:</span>
                    <span className="itemValue">
                      {userData.landlineNumber1}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Landline Number 2:</span>
                    <span className="itemValue">
                      {userData.landlineNumber2}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address:</span>
                    <span className="itemValue">{userData.address}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">City:</span>
                    <span className="itemValue">{userData.city}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">State:</span>
                    <span className="itemValue">{userData.state}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">{userData.country}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address 2:</span>
                    <span className="itemValue">{userData.address2}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">City 2:</span>
                    <span className="itemValue">{userData.city2}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">State 2:</span>
                    <span className="itemValue">{userData.state}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Country 2:</span>
                    <span className="itemValue">{userData.country2}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Previous Organization:</span>
                    <span className="itemValue">
                      {userData.previousOrganization}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Previous Designation:</span>
                    <span className="itemValue">
                      {userData.previuosDesignation}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">User Profile:</span>
                    <span className="itemValue">{userData.userProfile}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">User Type:</span>
                    <span className="itemValue">
                      {userData.userType.length !== 0
                        ? userData.userType.map((type, index) => {
                            return index + 1 !== userData.userType.length
                              ? `${type.userType}, `
                              : `${type.userType}`;
                          })
                        : ""}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Account Manager:</span>
                    <span className="itemValue">{userData.accountManager}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Remarks :</span>
                    <span className="itemValue">{userData.remarks}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Remarks Date:</span>
                    <span className="itemValue">{userData.remarksDate}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Updated By:</span>
                    <span className="itemValue">{userData.updatedBy}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Profile Complete:</span>
                    <span className="itemValue">
                      {`${userData.profileComplete}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default UserPage;
