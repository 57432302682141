// import { Typography } from "@mui/material";
// import { useState } from "react";
// import RichTextEditor from "../Event/RichEditor";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// // import Image from 'next/image'
// import "react-datetime/css/react-datetime.css";

// function Agenda({ agendaDetailsData, setAgendaDetailsData }) {
//   const addData = () => {
//     setAgendaDetailsData({
//       groupHeading: "Event Schedule: Your Timely Guide",
//       description: "",
//       position: 7,
//       publish: false,
//       data: [],
//     });
//   };

//   const removeData = () => {
//     setAgendaDetailsData(null);
//   };

//   const [defaultDate, setDefaultDate] = useState("");

//   const handleAddAgenda = (defaultDate) => {
//     if (agendaDetailsData) {
//       const updatedData = { ...agendaDetailsData };
//       updatedData.data.push({
//         day: defaultDate ? new Date(defaultDate) : new Date(),
//         startTime: new Date().toISOString().split("T")[1].slice(0, 8),
//         endTime: new Date().toISOString().split("T")[1].slice(0, 8),
//         heading: "",
//         paragraph: "",
//         speakers: [],
//       });
//       setAgendaDetailsData(updatedData);
//     }
//   };

//   const handleRemoveAgenda = () => {
//     if (agendaDetailsData && agendaDetailsData.data.length > 0) {
//       const updatedData = { ...agendaDetailsData };
//       updatedData.data.pop();
//       setAgendaDetailsData(updatedData);
//     }
//   };

//   const updateAgendaData = (index, key, value) => {
//     setAgendaDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const handleAddSpeaker = (agendaIndex) => {
//     if (agendaDetailsData && agendaDetailsData.data[agendaIndex]) {
//       const updatedData = { ...agendaDetailsData };
//       updatedData.data[agendaIndex].speakers.push({
//         position: agendaDetailsData.data[agendaIndex].speakers.length + 1,
//         name: "",
//         image: "",
//         designation: "",
//         linkdinUrl: "",
//       });
//       setAgendaDetailsData(updatedData);
//     }
//   };

//   const handleRemoveSpeaker = (agendaIndex) => {
//     if (
//       agendaDetailsData &&
//       agendaDetailsData.data[agendaIndex] &&
//       agendaDetailsData.data[agendaIndex].speakers.length > 0
//     ) {
//       const updatedData = { ...agendaDetailsData };
//       updatedData.data[agendaIndex].speakers.pop();
//       setAgendaDetailsData(updatedData);
//     }
//   };

//   const updateSpeakerData = (agendaIndex, speakerIndex, key, value) => {
//     setAgendaDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((agenda, i) =>
//         i === agendaIndex
//           ? {
//             ...agenda,
//             speakers: agenda.speakers.map((speaker, j) =>
//               j === speakerIndex ? { ...speaker, [key]: value } : speaker
//             ),
//           }
//           : agenda
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");

//   const handleDateChange = (e) => {
//     const newDate = e.target.value;
//     setDefaultDate(newDate);
//     if (agendaDetailsData && agendaDetailsData.data.length > 0) {
//       const updatedData = { ...agendaDetailsData };
//       updatedData.data[updatedData.data.length - 1].day = new Date(newDate);
//       setAgendaDetailsData(updatedData);
//     }
//   };

//   // const formatDate = (date) => {
//   //   const year = date.getFullYear();
//   //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
//   //   const day = date.getDate().toString().padStart(2, "0");
//   //   const hours = date.getHours().toString().padStart(2, "0");
//   //   const minutes = date.getMinutes().toString().padStart(2, "0");

//   //   return `${year}-${month}-${day}T${hours}:${minutes}`;
//   // };
//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[150px] flex justify-between">
//             Agenda
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {agendaDetailsData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {agendaDetailsData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"groupHeading"}
//                 value={agendaDetailsData.groupHeading}
//                 onChange={(e) => {
//                   setAgendaDetailsData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Description
//               </label>
//               <RichTextEditor
//                 name={"description"}
//                 value={agendaDetailsData.description}
//                 onChange={(e) => {
//                   setAgendaDetailsData((prev) => ({
//                     ...prev,
//                     description: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={agendaDetailsData.position}
//                   onChange={(e) => {
//                     setAgendaDetailsData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={agendaDetailsData.publish}
//                       onChange={(e) => {
//                         setAgendaDetailsData((prev) => ({
//                           ...prev,
//                           publish: e.target.value,
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>

//             <div>
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 // className="border-[2px] border-[#489FC5] my-10"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Agenda Data
//                 <AddCircleIcon
//                   onClick={() => handleAddAgenda(defaultDate)}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemoveAgenda()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//                 <div className="flex items-center ml-8">
//                   <label className="mr-2.5">Date:</label>
//                   <input
//                     value={defaultDate}
//                     type="datetime-local"
//                     // onChange={(e) => setDefaultDate(e.target.value)}
//                     onChange={handleDateChange}
//                     className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] p-5 w-full"
//                   />
//                 </div>
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               {agendaDetailsData.data.map((agenda, agendaIndex) => (
//                 <div className="border-2 m-auto">

//                   <div className=" flex w-11/12 gap-8 my-6 mb-10 m-auto">
//                     <div className="flex-1">
//                       <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                         Day
//                       </label>
//                       <input
//                         type="date"
//                         value={new Date(agenda.day).toISOString().slice(0, 10)}
//                         onChange={(e) =>
//                           updateAgendaData(agendaIndex, "day", e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full  "
//                       />
//                     </div>
//                     <div className="flex-1">
//                       <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                         Start Time
//                       </label>
//                       <input
//                         type="time"
//                         value={agenda.startTime}
//                         onChange={(e) =>
//                           updateAgendaData(
//                             agendaIndex,
//                             "startTime",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full  "
//                       />
//                     </div>
//                     <div className="flex-1">
//                       <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                         End Time
//                       </label>
//                       <input
//                         type="time"
//                         value={agenda.endTime}
//                         onChange={(e) =>
//                           updateAgendaData(
//                             agendaIndex,
//                             "endTime",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full  "
//                       />
//                     </div>
//                   </div>

//                   <div className="flex w-11/12 gap-8 my-6 mb-10 m-auto">

//                     <div>
//                       <label className=" mb-6 text-xl font-medium text-[#489FC5]">
//                         heading
//                       </label>
//                       <div className="mx-auto">
//                         <RichTextEditor
//                           value={agendaDetailsData.data[agendaIndex].heading}
//                           onChange={(e) =>
//                             updateAgendaData(
//                               agendaIndex,
//                               "heading",
//                               e.target.value
//                             )
//                           }
//                         />
//                       </div>
//                     </div>
//                     <div>
//                       <label
//                         htmlFor=""
//                         className=" mb-6 text-xl  font-medium text-[#489FC5]"
//                       >
//                         paragraph
//                       </label>
//                       <RichTextEditor
//                         value={agendaDetailsData.data[agendaIndex].paragraph}
//                         onChange={(e) =>
//                           updateAgendaData(
//                             agendaIndex,
//                             "paragraph",
//                             e.target.value
//                           )
//                         }
//                       />
//                     </div>

//                   </div>

//                   <div className="w-[85%] border-2 m-auto">
//                     <Typography
//                       id="modal-modal-title"
//                       variant="h6"
//                       component="h2"
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         alignItems: "center",
//                         color: "#489FC5",
//                         fontSize: "30px",
//                         margin: "40px 0 0 68px",
//                       }}
//                     >
//                       Speaker Data
//                       <AddCircleIcon
//                         onClick={() => handleAddSpeaker(agendaIndex)}
//                         style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                       />
//                       <RemoveCircleIcon
//                         onClick={() => handleRemoveSpeaker(agendaIndex)}
//                         style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                       />
//                     </Typography>

//                     <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                     {agenda.speakers.map((speaker, speakerIndex) => (
//                       <div
//                         key={speakerIndex}
//                         className="flex flex-col w-11/12 my-6 mb-10 mx-auto gap-8"
//                       >
//                         <div className="flex flex-col gap-6">
//                           <div className="flex gap-8">
//                             <div className="flex-1">
//                               <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                                 Name
//                               </label>
//                               <input
//                                 type="text"
//                                 value={speaker.name}
//                                 onChange={(e) =>
//                                   updateSpeakerData(
//                                     agendaIndex,
//                                     speakerIndex,
//                                     "name",
//                                     e.target.value
//                                   )
//                                 }
//                                 className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                               />
//                             </div>
//                             <div className="flex-1">
//                               <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                                 Designation
//                               </label>
//                               <input
//                                 type="text"
//                                 value={speaker.designation}
//                                 onChange={(e) =>
//                                   updateSpeakerData(
//                                     agendaIndex,
//                                     speakerIndex,
//                                     "designation",
//                                     e.target.value
//                                   )
//                                 }
//                                 className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                               />
//                             </div>
//                           </div>
//                           <div className="flex gap-8">
//                             <div className="flex-1">
//                               <label className="mb-2 text-xl font-medium text-[#489FC5]">
//                                 LinkedIn URL
//                               </label>
//                               <input
//                                 type="text"
//                                 value={speaker.linkdinUrl}
//                                 onChange={(e) =>
//                                   updateSpeakerData(
//                                     agendaIndex,
//                                     speakerIndex,
//                                     "linkdinUrl",
//                                     e.target.value
//                                   )
//                                 }
//                                 className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                               />
//                             </div>
//                             <div className="flex justify-center items-center">
//                               <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                                 Image
//                                 <input
//                                   type="file"
//                                   onChange={(e) =>
//                                     updateSpeakerData(
//                                       agendaIndex,
//                                       speakerIndex,
//                                       "image",
//                                       e.target.files[0]
//                                     )
//                                   }
//                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                                 />
//                               </label>
//                               <img
//                                 className="max-w-[100px] max-h-[200px] object-cover rounded-lg ml-4"
//                                 src={speaker.image}
//                                 alt="Image_Uploaded"
//                               />
//                             </div>
//                           </div>
//                           <div className="flex-1">
//                             <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                               Position
//                             </label>
//                             <input
//                               value={speaker.position}
//                               type="number"
//                               onChange={(e) =>
//                                 updateSpeakerData(
//                                   agendaIndex,
//                                   speakerIndex,
//                                   "position",
//                                   e.target.value
//                                 )
//                               }
//                               className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-36"
//                             />
//                           </div>
//                         </div>
//                         <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                       </div>
//                     ))}
//                   </div>
//                   <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                 </div>
//               ))}
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default Agenda;


import { Typography } from "@mui/material";
import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { MdDelete } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";

function Agenda({ agendaDetailsData, setAgendaDetailsData }) {
  const addData = () => {
    setAgendaDetailsData([
      ...agendaDetailsData,
      {
        groupHeading: "Event Schedule: Your Timely Guide",
        description: "",
        position: agendaDetailsData.length + 1,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Agenda group added successfully", {

      position: toast.POSITION.TOP_RIGHT,

    });
  };

  const removeLastGroup = () => {
    if (agendaDetailsData.length > 0) {
      const updatedData = [...agendaDetailsData];
      updatedData.pop();
      setAgendaDetailsData(updatedData);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setAgendaDetailsData(agendaDetailsData.filter((_, i) => i !== index));
      toast.success("Agenda group deleted successfully", {

        position: toast.POSITION.TOP_RIGHT,
      })
    }

  };

  const handleAddAgenda = (groupIndex) => {
    const updatedData = [...agendaDetailsData];
    const selectedDate = defaultDate ? new Date(defaultDate) : new Date();

    updatedData[groupIndex].data.push({
      day: selectedDate,
      startTime: selectedDate.toTimeString().slice(0, 8),
      endTime: new Date(selectedDate.getTime() + 3600000).toTimeString().slice(0, 8), // Default 1 hour duration
      heading: "",
      paragraph: "",
      speakers: [],
    });

    setAgendaDetailsData(updatedData);
  };


  const handleRemoveAgenda = (groupIndex) => {
    const updatedData = [...agendaDetailsData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      setAgendaDetailsData(updatedData);
    }
  };

  const updateAgendaData = (groupIndex, agendaIndex, key, value) => {
    const updatedData = [...agendaDetailsData];
    updatedData[groupIndex].data[agendaIndex] = {
      ...updatedData[groupIndex].data[agendaIndex],
      [key]: value,
    };
    setAgendaDetailsData(updatedData);
  };

  const handleAddSpeaker = (groupIndex, agendaIndex) => {
    const updatedData = [...agendaDetailsData];
    updatedData[groupIndex].data[agendaIndex].speakers.push({
      position: updatedData[groupIndex].data[agendaIndex].speakers.length + 1,
      name: "",
      image: "",
      designation: "",
      linkdinUrl: "",
    });
    setAgendaDetailsData(updatedData);
  };

  const handleRemoveSpeaker = (groupIndex, agendaIndex) => {
    const updatedData = [...agendaDetailsData];
    if (updatedData[groupIndex].data[agendaIndex].speakers.length > 0) {
      updatedData[groupIndex].data[agendaIndex].speakers.pop();
      setAgendaDetailsData(updatedData);
    }
  };

  const updateSpeakerData = (groupIndex, agendaIndex, speakerIndex, key, value) => {
    const updatedData = [...agendaDetailsData];
    updatedData[groupIndex].data[agendaIndex].speakers[speakerIndex] = {
      ...updatedData[groupIndex].data[agendaIndex].speakers[speakerIndex],
      [key]: value,
    };
    setAgendaDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");
  const [defaultDate, setDefaultDate] = useState("");

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDefaultDate(newDate);
    if (agendaDetailsData && agendaDetailsData.length > 0) {
      const updatedData = [...agendaDetailsData];
      const lastGroupIndex = updatedData.length - 1;
      if (updatedData[lastGroupIndex].data.length > 0) {
        const lastAgendaIndex = updatedData[lastGroupIndex].data.length - 1;
        updatedData[lastGroupIndex].data[lastAgendaIndex].day = new Date(newDate);
        setAgendaDetailsData(updatedData);
      }
    }
  };
  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[150px] flex justify-between">
            Agenda
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {agendaDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {agendaDetailsData.map((agendaGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className="mt-1 border-2 border-solid border-[#489FC5] rounded-md"
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={agendaGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...agendaDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setAgendaDetailsData(updatedData);
                }}
              />
            </div>

            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={agendaGroup.description}
                onChange={(e) => {
                  const updatedData = [...agendaDetailsData];
                  updatedData[groupIndex].description = e.target.value;
                  setAgendaDetailsData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={agendaGroup.position}
                  onChange={(e) => {
                    const updatedData = [...agendaDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setAgendaDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={agendaGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...agendaDetailsData];
                        updatedData[groupIndex].publish = e.target.value === 'true';
                        setAgendaDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Agenda Data
                <AddCircleIcon
                  onClick={() => handleAddAgenda(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveAgenda(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
                <div className="flex items-center ml-8">
                  <label className="mr-2.5">Date:</label>
                  <input
                    value={defaultDate}
                    type="datetime-local"
                    // onChange={(e) => setDefaultDate(e.target.value)}
                    onChange={handleDateChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] p-5 w-full"
                  />
                </div>
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {agendaGroup.data.map((agenda, agendaIndex) => (
                <div key={agendaIndex} className="border-2 m-auto mt-2 mb-2">
                  {/* Agenda details (day, start time, end time, heading, paragraph) */}
                  <div className="flex w-11/12 gap-8 my-6 mb-10 m-auto">
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Day
                      </label>
                      <input
                        type="date"
                        value={new Date(agenda.day).toISOString().slice(0, 10)}
                        onChange={(e) =>
                          updateAgendaData(groupIndex, agendaIndex, "day", new Date(e.target.value))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Start Time
                      </label>
                      <input
                        type="time"
                        value={agenda.startTime}
                        onChange={(e) =>
                          updateAgendaData(groupIndex, agendaIndex, "startTime", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        End Time
                      </label>
                      <input
                        type="time"
                        value={agenda.endTime}
                        onChange={(e) =>
                          updateAgendaData(groupIndex, agendaIndex, "endTime", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                  </div>

                  <div className="flex w-11/12 gap-8 my-6 mb-10 m-auto">
                    <div>
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Heading
                      </label>
                      <div className="mx-auto">
                        <RichTextEditor
                          value={agenda.heading}
                          onChange={(e) =>
                            updateAgendaData(groupIndex, agendaIndex, "heading", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="mb-6 text-xl font-medium text-[#489FC5]"
                      >
                        Paragraph
                      </label>
                      <RichTextEditor
                        value={agenda.paragraph}
                        onChange={(e) =>
                          updateAgendaData(groupIndex, agendaIndex, "paragraph", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="w-[85%] border-2 m-auto">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "#489FC5",
                        fontSize: "30px",
                        margin: "40px 0 0 68px",
                      }}
                    >
                      Speaker Data
                      <AddCircleIcon
                        onClick={() => handleAddSpeaker(groupIndex, agendaIndex)}
                        style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                      />
                      <RemoveCircleIcon
                        onClick={() => handleRemoveSpeaker(groupIndex, agendaIndex)}
                        style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                      />
                    </Typography>

                    <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                    {agenda.speakers.map((speaker, speakerIndex) => (
                      <div
                        key={speakerIndex}
                        className="flex flex-col w-11/12 my-6 mb-10 mx-auto gap-8"
                      >
                        {/* Speaker details (name, designation, LinkedIn URL, image, position) */}
                        {/* ... (Keep the existing input fields for speaker details) */}
                        <div
                          key={speakerIndex}
                          className="flex flex-col w-11/12 my-6 mb-10 mx-auto gap-8"
                        >
                          <div className="flex flex-col gap-6">
                            <div className="flex gap-8">
                              <div className="flex-1">
                                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  value={speaker.name}
                                  onChange={(e) =>
                                    updateSpeakerData(groupIndex, agendaIndex, speakerIndex, "name", e.target.value)
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                                />
                              </div>
                              <div className="flex-1">
                                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  value={speaker.designation}
                                  onChange={(e) =>
                                    updateSpeakerData(groupIndex, agendaIndex, speakerIndex, "designation", e.target.value)
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                                />
                              </div>
                            </div>
                            <div className="flex gap-8">
                              <div className="flex-1">
                                <label className="mb-2 text-xl font-medium text-[#489FC5]">
                                  LinkedIn URL
                                </label>
                                <input
                                  type="text"
                                  value={speaker.linkdinUrl}
                                  onChange={(e) =>
                                    updateSpeakerData(groupIndex, agendaIndex, speakerIndex, "linkdinUrl", e.target.value)
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                                />
                              </div>
                              <div className="flex justify-center items-center">
                                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                                  Image
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      updateSpeakerData(groupIndex, agendaIndex, speakerIndex, "image", e.target.files[0])
                                    }
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                                  />
                                </label>
                                <img
                                  className="max-w-[100px] max-h-[200px] object-cover rounded-lg ml-4"
                                  src={speaker.image}
                                  alt="Image_Uploaded"
                                />
                              </div>
                            </div>
                            <div className="flex-1">
                              <label className="mb-6 text-xl font-medium text-[#489FC5]">
                                Position
                              </label>
                              <input
                                value={speaker.position}
                                type="number"
                                onChange={(e) =>
                                  updateSpeakerData(groupIndex, agendaIndex, speakerIndex, "position", e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-36"
                              />
                            </div>
                            <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default Agenda;
