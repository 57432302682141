// import { useState } from "react";
// import RichTextEditor from "../Event/RichEditor";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// import { Typography } from "@mui/material";

// function Overview({ overviewDetailsData, setOverviewDetailsData }) {
//   // console.log(overviewDetailsData);
//   const addData = () => {
//     setOverviewDetailsData({
//       groupHeading: "Overview",
//       position: 0,
//       publish: false,
//       subHeading: "",
//       description: "",
//       data: [],
//     });
//   };

//   const removeData = () => {
//     setOverviewDetailsData(null);
//   };

//   const handleAddPreviousOverview = () => {
//     if (overviewDetailsData) {
//       const updatedData = { ...overviewDetailsData };
//       updatedData.data.push({
//         position: overviewDetailsData.data.length + 1,
//         image: "",
//       });
//       setOverviewDetailsData(updatedData);
//     }
//   };

//   const handleRemovePreviousOverview = () => {
//     if (overviewDetailsData && overviewDetailsData.data.length > 0) {
//       const updatedData = { ...overviewDetailsData };
//       updatedData.data.pop();
//       setOverviewDetailsData(updatedData);
//     }
//   };

//   const updatePreviousOverviewData = (index, key, value) => {
//     console.log("Overview", value);
//     setOverviewDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");
//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[200px] flex justify-around">
//             Overview
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {overviewDetailsData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {overviewDetailsData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>

//               <RichTextEditor
//                 name="groupHeading"
//                 value={overviewDetailsData.groupHeading}
//                 onChange={(e) => {
//                   setOverviewDetailsData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label
//                   htmlFor=""
//                   className=" mb-6   text-xl  font-medium text-[#489FC5]"
//                 >
//                   Sub Heading
//                 </label>
//                 <RichTextEditor
//                   name="subHeading"
//                   value={overviewDetailsData.subHeading}
//                   onChange={(e) => {
//                     setOverviewDetailsData((prev) => ({
//                       ...prev,
//                       subHeading: e.target.value,
//                     }));
//                   }}
//                 />
//               </div>
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label
//                   htmlFor=""
//                   className=" mb-6   text-xl  font-medium text-[#489FC5]"
//                 >
//                   Description
//                 </label>
//                 <RichTextEditor
//                   name={"description"}
//                   value={overviewDetailsData.description}
//                   onChange={(e) => {
//                     setOverviewDetailsData((prev) => ({
//                       ...prev,
//                       description: e.target.value,
//                     }));
//                   }}
//                 />
//               </div>
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={overviewDetailsData.position}
//                   onChange={(e) => {
//                     setOverviewDetailsData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={overviewDetailsData.publish}
//                       onChange={(e) => {
//                         setOverviewDetailsData((prev) => ({
//                           ...prev,
//                           publish: e.target.value,
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>

//             {/* ********************************************************************* */}
//             <div className="w-full">
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Previous Event Data
//                 <AddCircleIcon
//                   onClick={() => handleAddPreviousOverview()}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemovePreviousOverview()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               {overviewDetailsData.data.map((_, index) => (
//                 <div
//                   className="w-full flex gap-10"
//                   style={{
//                     visibility: hide ? "hidden" : "visible",
//                     height: hide ? "0" : "auto",
//                   }}
//                 >
//                   <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
//                     <div className="flex-1 flex">
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "column",
//                           alignItems: "center",
//                         }}
//                       >
//                         <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                           Image
//                           <input
//                             type="file"
//                             accept="image/*"
//                             onChange={(e) => {
//                               const file = e.target.files[0];
//                               const reader = new FileReader();
//                               reader.onloadend = () => {
//                                 updatePreviousOverviewData(
//                                   index,
//                                   "image",
//                                   file
//                                 );
//                               };
//                               if (file) {
//                                 reader.readAsDataURL(file);
//                               }
//                             }}
//                             className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                           />
//                         </label>
//                       </div>
//                       {overviewDetailsData.data[index].image && (
//                         <img
//                           className="w-[150px] h-auto object-cover rounded-lg ml-4"
//                           src={overviewDetailsData.data[index].image}
//                           alt="Uploaded"
//                         />
//                       )}
//                     </div>
//                   </div>

//                   <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto ">
//                     <div className=" flex-1">
//                       <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
//                         Position
//                       </label>
//                       <input
//                         value={overviewDetailsData.data[index].position}
//                         type="number"
//                         onChange={(e) =>
//                           updatePreviousOverviewData(
//                             index,
//                             "position",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-36      "
//                       />
//                     </div>
//                   </div>
//                 </div>
//               ))}
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//             </div>
//             {/* ********************************************************************* */}
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default Overview;


import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { MdDelete } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { toast } from "react-toastify";

function Overview({ overviewDetailsData, setOverviewDetailsData }) {
  const addData = () => {
    setOverviewDetailsData([
      ...overviewDetailsData,
      {
        groupHeading: "Overview",
        position: 0,
        publish: false,
        subHeading: "",
        description: "",
        overviewBtn: "",
        data: [],
      },
    ]);
    toast.success("New Overview group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (overviewDetailsData.length > 0) {
      const updatedData = [...overviewDetailsData];
      updatedData.pop();
      setOverviewDetailsData(updatedData);
      toast.success("Last Overview group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No Overview groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this Overview section?")) {
      setOverviewDetailsData(overviewDetailsData.filter((_, i) => i !== index));
      toast.success("Overview group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddPreviousOverview = (index) => {
    const updatedData = [...overviewDetailsData];
    updatedData[index].data.push({
      position: updatedData[index].data.length + 1,
      image: "",
    });
    setOverviewDetailsData(updatedData);
  };

  const handleRemovePreviousOverview = (index) => {
    const updatedData = [...overviewDetailsData];
    if (updatedData[index].data.length > 0) {
      updatedData[index].data.pop();
      setOverviewDetailsData(updatedData);
    }
  };

  const updatePreviousOverviewData = (groupIndex, dataIndex, key, value) => {
    const updatedData = [...overviewDetailsData];
    updatedData[groupIndex].data[dataIndex] = {
      ...updatedData[groupIndex].data[dataIndex],
      [key]: value,
    };
    setOverviewDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[200px] flex justify-around">
            Overview
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {overviewDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {overviewDetailsData.map((overviewGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name="groupHeading"
                value={overviewGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...overviewDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setOverviewDetailsData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label
                  htmlFor=""
                  className=" mb-6   text-xl  font-medium text-[#489FC5]"
                >
                  Sub Heading
                </label>
                <RichTextEditor
                  name="subHeading"
                  value={overviewGroup.subHeading}
                  onChange={(e) => {
                    const updatedData = [...overviewDetailsData];
                    updatedData[groupIndex].subHeading = e.target.value;
                    setOverviewDetailsData(updatedData);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label
                  htmlFor=""
                  className=" mb-6   text-xl  font-medium text-[#489FC5]"
                >
                  Description
                </label>
                <RichTextEditor
                  name={"description"}
                  value={overviewGroup.description}
                  onChange={(e) => {
                    const updatedData = [...overviewDetailsData];
                    updatedData[groupIndex].description = e.target.value;
                    setOverviewDetailsData(updatedData);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={overviewGroup.position}
                  onChange={(e) => {
                    const updatedData = [...overviewDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setOverviewDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={overviewGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...overviewDetailsData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        setOverviewDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                    Overview Button
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                      type="text"
                      value={overviewGroup.overviewBtn}
                      onChange={(e) => {
                        const updatedData = [...overviewDetailsData];
                        updatedData[groupIndex].overviewBtn = e.target.value;
                        setOverviewDetailsData(updatedData);
                      }}
                    >
                    </input>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Previous Event Data
                <AddCircleIcon
                  onClick={() => handleAddPreviousOverview(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemovePreviousOverview(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {overviewGroup.data.map((item, dataIndex) => (
                <div
                  key={dataIndex}
                  className="w-full flex gap-10"
                >
                  <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <div className="flex-1 flex">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <label className="mb-6 text-xl font-medium text-[#489FC5]">
                          Image
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                updatePreviousOverviewData(
                                  groupIndex,
                                  dataIndex,
                                  "image",
                                  file
                                );
                              };
                              if (file) {
                                reader.readAsDataURL(file);
                              }
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                          />
                        </label>
                      </div>
                      {item.image && (
                        <img
                          className="w-[150px] h-auto object-cover rounded-lg ml-4"
                          src={item.image}
                          alt="Uploaded"
                        />
                      )}
                    </div>
                  </div>

                  <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto ">
                    <div className=" flex-1">
                      <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                        Position
                      </label>
                      <input
                        value={item.position}
                        type="number"
                        onChange={(e) =>
                          updatePreviousOverviewData(
                            groupIndex,
                            dataIndex,
                            "position",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-36      "
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default Overview;
