import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

const AboutUsPage = ({ inputs, title }) => {
  const [openAboutUsPara, setOpenAboutUsPara] = useState(false);

  const handleOpenAboutUsPara = () => {
    setOpenAboutUsPara(true);
  };
  const handleCloseAboutUsPara = () => setOpenAboutUsPara(false);

  const [aboutUsPara, setAboutUsPara] = useState([]);

  const addAboutUsPara = () => {
    let list = [...aboutUsPara];
    list.push("");
    setAboutUsPara(list);
  };
  const removeAboutUsPara = () => {
    if (aboutUsPara.length > 0) {
      let list = [...aboutUsPara];
      list.pop();
      setAboutUsPara(list);
    }
  };

  const [openImageSection, setOpenImageSection] = useState(false);

  const handleOpenImageSection = () => {
    setOpenImageSection(true);
  };
  const handleCloseImageSection = () => setOpenImageSection(false);

  const [imageSection, setImageSection] = useState([]);

  const addImageSection = () => {
    let list = [...imageSection];
    list.push("");
    setImageSection(list);
  };
  const removeImageSection = () => {
    if (imageSection.length > 0) {
      let list = [...imageSection];
      list.pop();
      setImageSection(list);
    }
  };

  const [aboutUsData, setAboutUsData] = useState({
    banner: "",
    aboutUsHeading: "",
    aboutUsPara: [],
    missionContent: "",
    visionContent: "",
    imageSection: [],
    chairmanMessage: "",
    chairmanImage: "",
    legalAndFinancials: ""
  });

  useEffect(() => {
    getAboutUs();
  }, []);

  const getAboutUs = async () => {
    try {
      const response = await Services.getAboutUs();
      if (response.data.length > 0) {
        setAboutUsData(response.data[0]);
        setAboutUsPara(response.data[0].aboutUsPara);
        setImageSection(response.data[0].imageSection);
      }
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Services.updateAboutUs({
        ...aboutUsData,
        aboutUsPara: aboutUsPara,
        imageSection: imageSection
    });

    if (response.success) {
      toast.success("About us page updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    } catch (error) {
      if (error.response.data.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setAboutUsData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={onSubmit}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>
                    {input.label}
                    {input.required && "*"}
                  </label>
                  {input.name === "missionContent" ||
                  input.name === "visionContent" ||
                  input.name === "chairmanMessage" ? (
                    <textarea
                      name={input.name}
                      placeholder={input.placeholder}
                      value={aboutUsData[input.name]}
                      onChange={handleChange}
                      rows="5"
                      cols="33"
                    ></textarea>
                  ) : input.name === "aboutUsPara" ? (
                    <div
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="input-container"
                      onClick={handleOpenAboutUsPara}
                    >
                      <EditIcon />
                      Add
                    </div>
                  ) : input.name === "imageSection" ? (
                    <div
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="input-container"
                      onClick={handleOpenImageSection}
                    >
                      <EditIcon />
                      Add
                    </div>
                  ) : (
                    <input
                      type={input.type}
                      name={input.name}
                      value={aboutUsData[input.name]}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={openAboutUsPara}
        onClose={handleCloseAboutUsPara}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            About Us
            <AddCircleIcon onClick={addAboutUsPara} />
            <RemoveCircleIcon
              onClick={removeAboutUsPara}
              style={{ color: aboutUsPara.length > 0 ? "black" : "grey" }}
            />
          </Typography>
          {aboutUsPara.map((ele, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px 0 0 10px",
                  padding: "10px 0 0 10px",
                }}
              >
                <OutlinedInput
                  style={{ width: "100%" }}
                  type="text"
                  value={ele}
                  placeholder="content"
                  rows="5"
                  multiline
                  onChange={(e) => {
                    let list = [...aboutUsPara];
                    list[index] = e.target.value;
                    setAboutUsPara(list);
                  }}
                />
                <hr style={{ marginTop: "20px" }} />
              </div>
            );
          })}{" "}
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={handleCloseAboutUsPara}
          >
            Close
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openImageSection}
        onClose={handleCloseImageSection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            Image Section
            <AddCircleIcon onClick={addImageSection} />
            <RemoveCircleIcon
              onClick={removeImageSection}
              style={{ color: imageSection.length > 0 ? "black" : "grey" }}
            />
          </Typography>
          {imageSection.map((ele, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px 0 0 10px",
                  padding: "10px 0 0 10px",
                }}
              >
                <OutlinedInput
                  style={{ width: "100%" }}
                  type="text"
                  value={ele}
                  placeholder="content"
                  onChange={(e) => {
                    let list = [...imageSection];
                    list[index] = e.target.value;
                    setImageSection(list);
                  }}
                />
                <hr style={{ marginTop: "20px" }} />
              </div>
            );
          })}{" "}
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={handleCloseImageSection}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AboutUsPage;
