import React from "react";
import {
  ClassicEditor,
  Context,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  ContextWatchdog,
  Underline,
  Strikethrough,
  BlockQuote,
  Heading,
  Link,
  List,
  Code,
  Subscript,
  Superscript,
  Highlight,
  SourceEditing,
  Image,
  ImageToolbar,
  ImageStyle,
  Table,
  TableToolbar,
  ImageUpload,
  FontFamily,
  FontSize,
  FontColor,
  FontBackgroundColor,
  Alignment,
  MediaEmbed,
  MediaEmbedEditing,
  MediaEmbedToolbar,
  MediaEmbedUI,
  RemoveFormat,
  ImageInsert,
  ImageStyleUI,
  ImageResize,
  // ImageResizeButtons,
  // ImageResizeEditing,
  // ImageResizeHandles
} from "ckeditor5";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";


import "ckeditor5/ckeditor5.css";
import "./RichTextEditor.css"; // Custom CSS file

const customColorPalette = [
  { color: "hsl(4, 90%, 58%)", label: "Red" },
  { color: "hsl(340, 82%, 52%)", label: "Pink" },
  { color: "hsl(291, 64%, 42%)", label: "Purple" },
  { color: "hsl(262, 52%, 47%)", label: "Deep Purple" },
  { color: "hsl(231, 48%, 48%)", label: "Indigo" },
  { color: "hsl(207, 90%, 54%)", label: "Blue" },
];

const RichTextEditor = ({ value, onChange, name }) => {
  const CKEDITOR_5_CONFIGS = {
    extends: {
      toolbars: [
        ["undo", "redo", "removeFormat"],
        ["|", "heading"],
        ["outdent", "indent"],
        ["bold", "italic", "link", "underline", "highlight"],
        ["|", "sourceEditing"],
        ["bulletedList", "numberedList", "todoList"],
        ["blockQuote",'insertImage', "imageStyleUI","mediaEmbed"],
        [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "alignment",
          "removeFormat",
          "insertTable",
        ],
        ["|", "strikethrough", "code", "subscript", "superscript"],
      ],
      fontSize:{options:['12px','14px','16px','18px','20px','24px','28px','32px','40px','48px','60px']},
      'fontFamily': {
        'options': [
            'Montserrat, sans-serif',
            'Roboto, sans-serif',
            'Poppins, sans-serif',
            'Gulory, sans-serif',
            'Gotham, sans-serif',
            'Raleway, sans-serif',
            'Open Sans, sans-serif',
            'Cabin, sans-serif',
            'Futura, sans-serif',
            'Sitka, sans-serif',
            'Arizona, sans-serif',
            'Atures, sans-serif',
            'Bauhaus 93, sans-serif',
            'Bebas, sans-serif',
            'Berlin Sans, sans-serif',
            'Bodoni, serif',
            'Century, sans-serif',
            'Cooper black, sans-serif',
            'Eras, sans-serif',
            'Franklin, sans-serif',
            'Fugaz one, sans-serif',
            'Gill Sans, sans-serif',
            'Lato, sans-serif',
            'Muli, sans-serif',
            'Lord, sans-serif',
            'Nexa, sans-serif',
            'Oswald, sans-serif',
            'Rubi, sans-serif',
            'Arial, Helvetica, sans-serif',
            'Comic Sans MS, cursive, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
            'Arial Black, Gadget, sans-serif',
            'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, sans-serif',
            'Palatino Linotype, Book Antiqua, Palatino, serif',
            'Bookman Old Style, serif',
            'Garamond, serif',
            'Impact, Charcoal, sans-serif',
            'Helvetica Neue, Helvetica, Arial, sans-serif',
            'Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif',
            'Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif',
            'Century Gothic, sans-serif',
            'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            'Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif',
            'Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif',
            'Arial Narrow, sans-serif',
            'Futura, Trebuchet MS, Arial, sans-serif',
            'Geneva, Tahoma, Verdana, sans-serif',
            'Baskerville, Times New Roman, Times, serif',
            'Consolas, monospace',
            'Copperplate, Copperplate Gothic Light, fantasy',
            'Courier, Courier New, monospace',
            'Didot, serif',
            'Garamond, serif',
            'Perpetua, serif',
            'Rockwell, serif',
            'Rockwell Extra Bold, serif',
            'Brush Script MT, cursive',
            'Calibri, sans-serif',
            'Cambria, Georgia, serif',
            'Candara, Arial, sans-serif',
            'Corbel, sans-serif',
            'Constantia, serif',
            'Andale Mono, monospace',
            'Lucida Console, Monaco, monospace',
            'Monaco, monospace',
            'Papyrus, fantasy',
            'Verdana, Geneva, sans-serif',
        ],},
      image: {
        toolbar: [
          "imageTextAlternative",
          "|",
          "imageStyle:alignLeft",
          "imageStyle:alignRight",
          "imageStyle:alignCenter",
          "imageStyle:side",
          "|",
          "imageStyle:responsive",
          "resizeImage",
        ],
        styles: ["full", "side", "alignLeft", "alignRight", "alignCenter","responsive"],
      },
      
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
        tableProperties: {
          borderColors: customColorPalette,
          backgroundColors: customColorPalette,
        },
        tableCellProperties: {
          borderColors: customColorPalette,
          backgroundColors: customColorPalette,
        },
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
          {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
          },
        ],
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    
  };


  class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
  
  
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("file", file);
                console.log(formData)
                fetch(`${process.env.REACT_APP_SITE_URL}/ckeditor/upload`, {
                    method: 'POST',
                    body: formData,
                    headers:{
                      // "Content-Type": "multipart/form-data",
                      "api-key": `${process.env.REACT_APP_API_KEY}`,
                      authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).jwtToken}`,
                    }
                })
                .then(response => response.json())
                .then(result => {
                  console.log("####################################",result)
                    if (result && result.url) {
                        
                        resolve({
                            default: result.url
                        });
                    } else {
                        console.log(result)
                        reject(result.message || 'Upload failed');
                    }
                })
                .catch(reject);
            }));
    }
  
    abort() {
        // Handle aborting the upload process if needed
    }
  }
  


  function MyCustomUploadAdapterPlugin(editor) {
    console.log("working")
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
  }

  return (
    <div className="editor-container w-11/12">
      <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            plugins: [
              Essentials,
              Bold,
              Italic,
              Paragraph,
              SourceEditing,
              Image,
              ImageToolbar,
              ImageStyle,
              Table,
              TableToolbar,
              ImageUpload,
              FontFamily,
              FontSize,
              FontColor,
              FontBackgroundColor,
              Alignment,
              Underline,
              Strikethrough,
              BlockQuote,
              Heading,
              Link,
              List,
              Code,
              Subscript,
              Superscript,
              Highlight,
              MediaEmbed,
              MediaEmbedEditing,
              MediaEmbedToolbar,
              MediaEmbedUI,
              RemoveFormat,
              ImageInsert,
              ImageStyleUI,
              ImageResize,
            ],
            extraPlugins: [MyCustomUploadAdapterPlugin],
            toolbar: {
              items: CKEDITOR_5_CONFIGS.extends.toolbars.flat(),
              shouldNotGroupWhenFull: true,
            },
            image: CKEDITOR_5_CONFIGS.extends.image,
            table: CKEDITOR_5_CONFIGS.extends.table,
            heading: CKEDITOR_5_CONFIGS.extends.heading,
            list: CKEDITOR_5_CONFIGS.list,
            fontSize: CKEDITOR_5_CONFIGS.extends.fontSize,
            fontFamily: CKEDITOR_5_CONFIGS.extends.fontFamily,
            mediaEmbed:CKEDITOR_5_CONFIGS.extends.mediaEmbed
          }}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange({ target: { name, value: data } });
          }}
        />
      </CKEditorContext>
    </div>
  );
};

export default RichTextEditor;
