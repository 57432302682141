import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import { OutlinedInput } from "@mui/material";
// import { InputLabel } from "@mui/material";
import "../new.scss";
import RichTextEditor from "./RichEditor";
import KeyTracks from "../NewEvents/KeyTracks";
import Partners from "../NewEvents/Partners";
import WhyParticipate from "../NewEvents/WhyParticipate";
import Agenda from "../NewEvents/Agenda";
import Speaker from "../NewEvents/Speaker";
import Registration from "../NewEvents/Registration";
import Faqs from "../NewEvents/Faqs";
import PreviousEvent from "../NewEvents/PreviousEvent";
import ContactUs from "../NewEvents/ContactUs";
import Venue from "../NewEvents/Venue";
import Overview from "../NewEvents/Overview";
// import KeyHighlight from "../NewEvents/KeyHighlight";
import DiscussionPoints from "../NewEvents/DiscussionPoints";
import MiscellaneousEvent from "../NewEvents/MiscellaneousEvent";
import Awards from "../NewEvents/Awards";

const AddEventPage = ({ inputs, title }) => {
  const navigate = useNavigate();

  const [eventData, setEventData] = useState({
    eventName: "",
    eventDescription: "",
    eventModes: "default",
    eventType: "default",
    startDate: "",
    endDate: "",
    publish: false,
    pinned: false,
    recurringTag: "None",
    eventBannerFileType: "image",
    primaryColor: "#E62177",
    secondaryColor: "#2A3273",
    tertiaryColor: "#ebbcd0",
    fourthColor: "#E62177",
    fifthColor: "#2A3273",
    sixthColor: "#ebbcd0",
    fontFamily: "",
    metaTitle: "",
    metaData: "",
    eventBannerImage: "", 
    templateNo: 1
  });

  const [eventModes, setEventModes] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);

  useEffect(() => {
    getEventModes();
    getEventTypes();
  }, []);

  const getEventModes = async () => {
    try {
      const response = await Services.getAllEventModes();
      setEventModes(response.data);
    } catch (error) {
      toast.error(error.response.data.message || "Error!!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getEventTypes = async () => {
    try {
      const response = await Services.getAllEventTypes();
      setEventTypes(response.data);
    } catch (error) {
      toast.error(error.response.data.message || "Error!!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [eventPreviousData, SetEventPreviousData] = useState([]);
  const [keyTracksData, SetKeyTracksData] = useState([]);
  const [agendaDetailsData, setAgendaDetailsData] = useState([]);
  const [contactUsDetailsData, setContactUsDetailsData] = useState([]);
  const [discussionPointDetailsData, setDiscussionPointDetailsData] = useState([]);
  const [faqsDetailsData, setFaqsDetailsData] = useState([]);
  // const [keyHighlightDetailsData, setKeyHighlightDetailsData] = useState(null);
  const [overviewDetailsData, setOverviewDetailsData] = useState([]);
  const [partnersDetailsData, setPartnersDetailsData] = useState([]);
  const [registrationDetailsData, setRegistrationDetailsData] = useState([]);
  const [speakerDetailsData, setSpeakerDetailsData] = useState([]);
  const [whyParticipateDetailsData, setWhyParticipateDetailsData] = useState([]);
  const [awardDetailsData, setAwardDetailsData] = useState([]);
  const [venueDetailsData, setVenueDetailsData] = useState(null);
  const [miscellaneousDetailsData, setMiscellaneousDetailsData] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    const allNewData = {
      groups: {
        keyTracks: keyTracksData,
        registration: registrationDetailsData,
        overview: overviewDetailsData,
        partners: partnersDetailsData,
        // keyHighlights: [keyHighlightDetailsData],
        discussionPoints: discussionPointDetailsData,
        whyParticipate: whyParticipateDetailsData,
        awards: awardDetailsData,
        agenda: agendaDetailsData,
        speakers: speakerDetailsData,
        faqs: faqsDetailsData,
        previousEvent: eventPreviousData,
        contactUs: contactUsDetailsData,
        venue: venueDetailsData,
        miscellaneous: miscellaneousDetailsData,

      },
    };


    const additionalData = {
      "metaData": eventData.metaData.split(",").map(item => item.trim()),
      "metaTitle": eventData.metaTitle.split(",").map(item => item.trim())
    };

    try {
      const response = await Services.addEvent({
        ...eventData,
        ...allNewData,
        ...additionalData,
      });
      toast.success("Event Added", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (response) {
        navigate(`/events/update/${response?.eventId}`);
      }
    } catch (error) {
      if (Array.isArray(error.response?.data)) {
        error.response.data.forEach((message) => {
          toast.error(message.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      } else {
        toast.error("An unexpected error occurred.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    console.log(e.target);
    if (files) {
      setEventData((prevValue) => ({
        ...prevValue,
        [name]: files[0],
      }));
      return;
    }

    if (["paidEntry", "pinned", "publish"].includes(name)) {
      setEventData((prevValue) => ({
        ...prevValue,
        [name]: value === "true",
      }));
    } else {
      setEventData((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        {eventModes && eventTypes ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>
                      {input.label}
                      {input.required && "*"}
                    </label>
                    {input.name === "pinned" || input.name === "publish" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    ) : input.name === "eventModes" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {eventModes.map((mode) => {
                          return (
                            <option key={mode._id} value={mode._id}>
                              {mode.mode}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.type === "quill" ? (
                      <>
                        <RichTextEditor
                          name={input.name}
                          onChange={handleChange}
                          value={eventData[input.name]}
                        />
                      </>
                    ) : input.name === "eventType" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {eventTypes.map((type) => {
                          return (
                            <option key={type._id} value={type._id}>
                              {type.type}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.name === "recurringTag" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value="None">None</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    ) : input.name === "fontFamily" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={"default"}>
                          Choose an option
                        </option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Poppins">Poppins</option>
                        <option value="Gulory">Gulory</option>
                        <option value="Gotham">Gotham</option>
                        <option value="Raleway">Raleway</option>
                        <option value="Open Sans">Open Sans</option>
                        <option value="Cabin">Cabin</option>
                        <option value="Futura">Futura</option>
                        <option value="Sitka">Sitka</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Atures">Atures</option>
                        <option value="Bauhaus 93">Bauhaus 93</option>
                        <option value="Berlin Sans">Berlin Sans</option>
                        <option value="Bodoni, serif">Bodoni, serif</option>
                        <option value="Century">Century</option>
                        <option value="Cooper black">Cooper black</option>
                        <option value="Eras">Eras</option>
                        <option value="Franklin">Franklin</option>
                        <option value="Fugaz one">Fugaz one</option>
                        <option value="Gill Sans">Gill Sans</option>
                        <option value="Lato">Lato</option>
                        <option value="Muli">Muli</option>
                        <option value="Lord">Lord</option>
                        <option value="Nexa">Nexa</option>
                        <option value="Oswald">Oswald</option>
                        <option value="Rubi">Rubi</option>
                      </select>
                    ) : input.name === "eventBannerFileType" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value="image">image</option>
                        <option value="gif">gif</option>
                        <option value="Streaming">Streaming</option>
                      </select>
                    ) : input.type === "color" ? (
                      <div className="mt-4 flex items-center w-full">
                        <input
                          type={input.type}
                          name={input.name}
                          value={eventData[input.name]}
                          placeholder={input.placeholder}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                          }}
                        />
                        <input
                          type="text"
                          name={input.name}
                          value={eventData[input.name]}
                          placeholder={input.placeholder}
                          onChange={handleChange}
                        />
                      </div>
                    ) : (
                      <input
                        type={input.type}
                        name={input.name}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                      />
                    )}
                  </div>
                ))}
                <div className="p-8">
                  <div className="flex space-x-10">
                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Primary Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="primaryColor"
                          value={eventData.primaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="primaryColor"
                          value={eventData.primaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Secondary Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="secondaryColor"
                          value={eventData.secondaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="secondaryColor"
                          value={eventData.secondaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Tertiary Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="tertiaryColor"
                          value={eventData.tertiaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="tertiaryColor"
                          value={eventData.tertiaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-10">
                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Fourth Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="fourthColor"
                          value={eventData.fourthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="fourthColor"
                          value={eventData.fourthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Fifth Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="fifthColor"
                          value={eventData.fifthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="fifthColor"
                          value={eventData.fifthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Sixth Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="sixthColor"
                          value={eventData.sixthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="sixthColor"
                          value={eventData.sixthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full "
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col">
                    <label className=" mb-2  mt-6 text-l   font-medium text-[#489FC5]">Page Description</label>

                    <textarea
                      name="pageDescription"
                      value={eventData.pageDescription}
                      placeholder="Page Description"
                      onChange={handleChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-600"
                      rows="5" // Increased number of lines
                    />
                  </div>

                </div>

                <div className="flex w-full flex-col">
                  <Overview
                    overviewDetailsData={overviewDetailsData}
                    setOverviewDetailsData={setOverviewDetailsData}
                  />
                  <Agenda
                    agendaDetailsData={agendaDetailsData}
                    setAgendaDetailsData={setAgendaDetailsData}
                  />
                  <Speaker
                    speakerDetailsData={speakerDetailsData}
                    setSpeakerDetailsData={setSpeakerDetailsData}
                  />
                  <KeyTracks
                    keyTracksData={keyTracksData}
                    SetKeyTracksData={SetKeyTracksData}
                  />
                  <DiscussionPoints
                    discussionPointDetailsData={discussionPointDetailsData}
                    setDiscussionPointDetailsData={
                      setDiscussionPointDetailsData
                    }
                  />
                  <WhyParticipate
                    whyParticipateDetailsData={whyParticipateDetailsData}
                    setWhyParticipateDetailsData={setWhyParticipateDetailsData}
                  />
                  <Partners
                    partnersDetailsData={partnersDetailsData}
                    setPartnersDetailsData={setPartnersDetailsData}
                  />
                  <Registration
                    registrationDetailsData={registrationDetailsData}
                    setRegistrationDetailsData={setRegistrationDetailsData}
                  />
                  <Faqs
                    faqsDetailsData={faqsDetailsData}
                    setFaqsDetailsData={setFaqsDetailsData}
                  />
                  <Awards awardDetailsData={awardDetailsData}
                    setAwardDetailsData={setAwardDetailsData} />

                  <PreviousEvent
                    eventPreviousData={eventPreviousData}
                    SetEventPreviousData={SetEventPreviousData}
                  />
                  <ContactUs
                    contactUsDetailsData={contactUsDetailsData}
                    setContactUsDetailsData={setContactUsDetailsData}
                  />
                  <Venue
                    venueDetailsData={venueDetailsData}
                    setVenueDetailsData={setVenueDetailsData}
                  />
                  <MiscellaneousEvent
                    miscellaneousDetailsData={miscellaneousDetailsData}
                    setMiscellaneousDetailsData={setMiscellaneousDetailsData}
                  />
                </div>
                {/* <KeyHighlight
                    keyHighlightDetailsData={keyHighlightDetailsData}
                    setKeyHighlightDetailsData={setKeyHighlightDetailsData}
                  /> */}
                <button className="py-2 px-6 rounded-md text-xl bg-[#489FC5] text-white mx-auto">
                  Send
                </button>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
//
export default AddEventPage;
