// import { useState } from "react";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import RichTextEditor from "../Event/RichEditor";
// // import Image from "next/image";
// import "../new.scss";
// import { Typography } from "@mui/material";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";

// // const style = {
// //   position: "absolute",
// //   top: "50%",
// //   left: "50%",
// //   transform: "translate(-50%, -50%)",
// //   width: "90%",
// //   maxHeight: "80%",
// //   BgColor: "background.paper",
// //   border: "2px solid #000",
// //   boxShadow: 24,
// //   overflowY: "auto",
// //   p: 4,
// // };

// function Speaker({ speakerDetailsData, setSpeakerDetailsData }) {
//   const addData = () => {
//     setSpeakerDetailsData({
//       groupHeading: "Meet Our Speakers",
//       description: "",
//       position: 0,
//       publish: false,
//       data: [],
//     });
//   };

//   const removeData = () => {
//     setSpeakerDetailsData(null);
//   };

//   const handleAddSpeaker = () => {
//     if (speakerDetailsData) {
//       const updatedData = { ...speakerDetailsData };
//       updatedData.data.push({
//         position: speakerDetailsData.data.length + 1,
//         name: "",
//         image: "",
//         designation: "",
//         linkdinUrl: "",
//       });
//       setSpeakerDetailsData(updatedData);
//     }
//   };

//   const handleRemoveSpeaker = (index) => {
//     if (speakerDetailsData && speakerDetailsData.data.length > 0) {
//       const updatedData = { ...speakerDetailsData };
//       updatedData.data.splice(index, 1);
//       setSpeakerDetailsData(updatedData);
//     }
//   };

//   const updateSpeakerData = (index, key, value) => {
//     setSpeakerDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");

//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[150px] flex justify-between">
//             Speaker
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {speakerDetailsData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {speakerDetailsData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className=" w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"heading"}
//                 value={speakerDetailsData.groupHeading}
//                 onChange={(e) => {
//                   setSpeakerDetailsData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className=" w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Description
//               </label>
//               <RichTextEditor
//                 name={"description"}
//                 value={speakerDetailsData.description}
//                 onChange={(e) => {
//                   setSpeakerDetailsData((prev) => ({
//                     ...prev,
//                     description: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="flex gap-8 w-6/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={speakerDetailsData.position}
//                   onChange={(e) => {
//                     setSpeakerDetailsData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={speakerDetailsData.publish}
//                       onChange={(e) => {
//                         setSpeakerDetailsData((prev) => ({
//                           ...prev,
//                           publish: e.target.value === "true",
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div className="flex flex-col gap-8 border-2 border-gray-300 w-11/12">
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Speaker Data
//                 <AddCircleIcon
//                   onClick={() => handleAddSpeaker()}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemoveSpeaker()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10 "></div>
//               {speakerDetailsData.data.map((speaker, index) => (
//                 <div key={index}>

//                   <div className=" w-full gap-4 mb-10 mx-auto">
//                     <div className="ml-10 mt-6">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Name
//                       </label>
//                       <RichTextEditor
//                         value={speaker.name}
//                         type="text"
//                         onChange={(e) =>
//                           updateSpeakerData(index, "name", e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-2 w-full"
//                       />
//                     </div>
//                     <div className="ml-10 mt-6">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Designation
//                       </label>
//                       <RichTextEditor
//                         value={speaker.designation}
//                         type="text"
//                         onChange={(e) =>
//                           updateSpeakerData(
//                             index,
//                             "designation",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-2 w-full"
//                       />
//                     </div>
//                   </div>


//                   <div className="flex w-full gap-4 my-6 mb-10 px-4">
//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Position
//                       </label>
//                       <input
//                         type="number"
//                         value={speaker.position}
//                         onChange={(e) =>
//                           updateSpeakerData(index, "position", e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>

//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         LinkedIn URL
//                         <input
//                           type="url"
//                           value={speaker.linkdinUrl}
//                           onChange={(e) =>
//                             updateSpeakerData(
//                               index,
//                               "linkdinUrl",
//                               e.target.value
//                             )
//                           }
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         />
//                       </label>
//                     </div>

//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Image
//                         <input
//                           type="file"
//                           accept="image/*"
//                           onChange={(e) => {
//                             const file = e.target.files[0];
//                             const reader = new FileReader();
//                             reader.onloadend = () => {
//                               updateSpeakerData(index, "image", file);
//                             };
//                             if (file) {
//                               reader.readAsDataURL(file);
//                             }
//                           }}
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         />
//                       </label>
//                       <img
//                         className="max-w-[100px] max-h-[200px] object-cover rounded-lg mt-2 m-auto"
//                         src={speaker.image}
//                         alt="Image_Uploaded"
//                       />
//                     </div>
//                   </div>

//                   <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                 </div>
//               ))}
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default Speaker;


import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RichTextEditor from "../Event/RichEditor";
import "../new.scss";
import { Typography } from "@mui/material";
import { MdDelete } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";


function Speaker({ speakerDetailsData, setSpeakerDetailsData }) {
  const addData = () => {
    setSpeakerDetailsData([
      ...speakerDetailsData,
      {
        groupHeading: "Meet Our Speakers",
        description: "",
        position: 0,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Speaker group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (speakerDetailsData.length > 0) {
      const updatedData = [...speakerDetailsData];
      updatedData.pop();
      setSpeakerDetailsData(updatedData);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setSpeakerDetailsData(speakerDetailsData.filter((_, i) => i !== index));
    }
    toast.success("Speaker group deleted successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleAddSpeaker = (index) => {
    const updatedData = [...speakerDetailsData];
    updatedData[index].data.push({
      position: updatedData[index].data.length + 1,
      name: "",
      image: "",
      designation: "",
      linkdinUrl: "",
    });
    setSpeakerDetailsData(updatedData);
  };

  const handleRemoveSpeaker = (groupIndex) => {
    const updatedData = [...speakerDetailsData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      setSpeakerDetailsData(updatedData);
    }
  };

  const updateSpeakerData = (groupIndex, speakerIndex, key, value) => {
    const updatedData = [...speakerDetailsData];
    updatedData[groupIndex].data[speakerIndex] = {
      ...updatedData[groupIndex].data[speakerIndex],
      [key]: value,
    };
    setSpeakerDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[150px] flex justify-between">
            Speaker
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {speakerDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>

        </h1>
      </div>
      {speakerDetailsData.map((speakerGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className="mt-1 border-2 border-solid border-[#489FC5] rounded-md"
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px",
              }}
            />
          </div>
          <form action="">
            <div className="w-11/12 mb-12">
              <label htmlFor="" className="mb-6 text-xl font-medium text-[#489FC5]">
                Group Heading
              </label>
              <RichTextEditor
                name={"heading"}
                value={speakerGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...speakerDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setSpeakerDetailsData(updatedData);
                }}
              />
            </div>

            <div className="w-11/12 mb-12">
              <label htmlFor="" className="mb-6 text-xl font-medium text-[#489FC5]">
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={speakerGroup.description}
                onChange={(e) => {
                  const updatedData = [...speakerDetailsData];
                  updatedData[groupIndex].description = e.target.value;
                  setSpeakerDetailsData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 w-6/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={speakerGroup.position}
                  onChange={(e) => {
                    const updatedData = [...speakerDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setSpeakerDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
              <div className="flex-1">
                <div className="">
                  <label className="w-8/12 gap-8 ml-16 text-xl font-medium text-[#489FC5]">
                    Publish :
                    <select
                      value={speakerGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...speakerDetailsData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        setSpeakerDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-8 border-2 border-gray-300 w-11/12 m-auto mb-2">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Speaker Data
                <AddCircleIcon
                  onClick={() => handleAddSpeaker(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveSpeaker(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {speakerGroup.data.map((speaker, speakerIndex) => (
                <div key={speakerIndex}>
                  <div className="w-full gap-4 mb-10 m-auto">
                    <div className="ml-10 mt-6">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Name
                      </label>
                      <RichTextEditor
                        value={speaker.name}
                        type="text"
                        onChange={(e) =>
                          updateSpeakerData(groupIndex, speakerIndex, "name", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-2 w-full"
                      />
                    </div>
                    <div className="ml-10 mt-6">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Designation
                      </label>
                      <RichTextEditor
                        value={speaker.designation}
                        type="text"
                        onChange={(e) =>
                          updateSpeakerData(groupIndex, speakerIndex, "designation", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-2 w-full"
                      />
                    </div>
                  </div>

                  <div className="flex w-full gap-4 my-6 mb-10 px-4">
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Position
                      </label>
                      <input
                        type="number"
                        value={speaker.position}
                        onChange={(e) =>
                          updateSpeakerData(groupIndex, speakerIndex, "position", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        LinkedIn URL
                        <input
                          type="url"
                          value={speaker.linkdinUrl}
                          onChange={(e) =>
                            updateSpeakerData(groupIndex, speakerIndex, "linkdinUrl", e.target.value)
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        />
                      </label>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Image
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              updateSpeakerData(groupIndex, speakerIndex, "image", file);
                            };
                            if (file) {
                              reader.readAsDataURL(file);
                            }
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        />
                      </label>
                      {speaker.image && (
                        <img
                          className="max-w-[100px] max-h-[200px] object-cover rounded-lg mt-2 m-auto"
                          src={speaker.image}
                          alt="Image_Uploaded"
                        />
                      )}
                    </div>
                  </div>

                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default Speaker;
