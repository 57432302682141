import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { Api } from "../../helper";
import RichTextEditor from '../Event/RichEditor';

const OurIncubators = () => {
    const [incubatees, setIncubatees] = useState([]);
    const [newIncubatee, setNewIncubatee] = useState({
        heading: '',
        description: '',
        logo: '',
        link: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editIncubatee, setEditIncubatee] = useState({
        _id: '',
        heading: '',
        description: '',
        logo: '',
        link: ''
    });

    useEffect(() => {
        fetchData();
    }, [isLoading]);

    const fetchData = async () => {
        try {
            const response = await Api.get('/incubator/incubatee');
            if (response.data && response.data.data) {
                setIncubatees(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAddIncubatee = async () => {
        setIsLoading(true);
        try {
            const response = await Api.post('/incubator/incubatee', newIncubatee, "multipart/form-data");
            setIncubatees([...incubatees, response.data]);
            console.log('Incubatee added successfully:', response.data);
            setNewIncubatee({
                heading: '',
                description: '',
                logo: '',
                link: ''
            });
        } catch (error) {
            console.error('Error adding incubatee:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditIncubatee = async () => {
        setIsLoading(true);
        try {
            const response = await Api.put(`/incubator/incubatee?id=${editIncubatee._id}`, editIncubatee, "multipart/form-data");
            if (response.status === 200) {
                const updatedIncubatees = incubatees.map(incubatee =>
                    incubatee._id === editIncubatee._id ? response.data : incubatee
                );
                setIncubatees(updatedIncubatees);
                console.log(`Incubatee with ID ${editIncubatee._id} updated successfully.`);
                setEditMode(false);
                setEditIncubatee({
                    _id: '',
                    heading: '',
                    description: '',
                    logo: '',
                    link: ''
                });
            }
        } catch (error) {
            console.error('Error updating incubatee:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (editMode) {
            setEditIncubatee(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setNewIncubatee(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleOnChangeImg = (name, files) => {
        console.log(files);
        if (files) {
            if (editMode) {
                setEditIncubatee(prevData => ({ ...prevData, [name]: files[0] }));
            } else {
                setNewIncubatee(prevData => ({ ...prevData, [name]: files[0] }));
            }
        }
    };

    const handleEditClick = (incubatee) => {
        setEditMode(true);
        setEditIncubatee(incubatee);
    };

    const renderForm = () => {
        const incubateeData = editMode ? editIncubatee : newIncubatee;
        const buttonText = editMode ? 'Save Changes' : 'Add Incubatee';

        return (
            <div className="mb-4">
                <div className="flex flex-col md:flex-row mb-2 items-center">
                    <input
                        type="text"
                        placeholder="Heading"
                        name="heading"
                        value={incubateeData.heading}
                        onChange={handleInputChange}
                        className="flex-1 px-4 py-2 md:mr-2 mb-2 md:mb-0 border rounded"
                    />
                    <input
                        type="file"
                        placeholder="Logo"
                        onChange={(e) => handleOnChangeImg("logo", e.target.files)}
                        className="px-4 py-2 md:mr-2 mb-2 md:mb-0 border rounded flex-1"
                    />
                    <input
                        type="text"
                        placeholder="Link"
                        name="link"
                        value={incubateeData.link}
                        onChange={handleInputChange}
                        className="flex-1 px-4 py-2 md:mr-2 mb-2 md:mb-0 border rounded"
                    />
                </div>

                <RichTextEditor
                    name="description"
                    onChange={handleInputChange}
                    value={incubateeData.description}
                />

                <div className="flex justify-center">
                    <button
                        onClick={editMode ? handleEditIncubatee : handleAddIncubatee}
                        className="bg-green-500 text-white px-4 py-2 rounded mt-3"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Processing...' : buttonText}
                    </button>
                </div>

                {editMode && (
                    <div className="flex justify-center mt-2">
                        <button
                            onClick={() => setEditMode(false)}
                            className="bg-gray-500 text-white px-4 py-2 rounded"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const truncate = (text, maxLength) => {
        if (!text) return '';
        return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };

    return (
        <div className="bg-white page">
            <Sidebar />
            <div className="listContainer p-4">
                <h1 className="text-3xl font-bold mb-4">Our Incubatees</h1>

                {renderForm()}

                <div className="flex flex-wrap justify-center">
                    {incubatees.map(incubatee => (
                        <div key={incubatee._id} className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 p-4">
                            <div className="border p-4 rounded">
                                <h2 className="text-xl font-semibold mb-2"><b>{incubatee.heading}</b></h2>
                                <p><b>ID:</b> {incubatee._id}</p>
                                <p><b>Description:</b> {truncate(incubatee.description, 50)}</p>
                                <p><b>Link:</b> {truncate(incubatee.link, 50)}</p>
                                <div className="flex mt-4 justify-center">
                                    <button
                                        onClick={() => handleEditClick(incubatee)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

export default OurIncubators;
