import "../singlepage.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useCallback, useEffect, useState } from "react";
import { Services } from "../../Services";
import { useLocation } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { OutlinedInput, InputLabel, Button } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import { adminColumns } from "../../datatablesource";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { adminType } from "../../constants";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdminPage = () => {
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [adminsData, setAdminsData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [newAdmin, setNewAdmin] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    password: "",
    adminType: "superAdmin",
  });

  const [deleteAdmin, setDeleteAdmin] = useState({
    _id: "",
    emailId: "",
  });

  const [updateAdmin, setUpdateAdmin] = useState({
    _id: "",
    emailId: "",
    password: "",
  });

  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const handleOpen = (_id, emailId) => {
    setDeleteAdmin({ _id, emailId });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleUpdateOpen = (_id, emailId) => {
    setUpdateAdmin({ _id, emailId });
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => setUpdateOpen(false);

  const refreshFn = () => {
    setRefresh(!refresh);
  };

  const getLogedInAdminInfo = useCallback(async () => {
    try {
      const response = await Services.getAdmin(location.state.userId);
      setUserData(response.data);
      console.log(response.data);
      toast.success("Admin fetched successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : "Something went wrong!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [location.state.userId]);

  useEffect(() => {
    getLogedInAdminInfo();
  }, [getLogedInAdminInfo]);

  useEffect(() => {
    const fetchAllAdminsInfo = async () => {
      try {
        const response = await Services.getAllAdmins();
        setAdminsData(response.data);
        toast.success("Admins fetched successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        toast.error(
          error.response.data.error
            ? error.response.data.error
            : "An error occurred while fetching admin data.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };

    fetchAllAdminsInfo();
  }, [refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewAdmin((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const registerAdmin = async () => {
    if (
      newAdmin.emailId === "" ||
      newAdmin.password === "" ||
      newAdmin.firstName === "" ||
      newAdmin.lastName === ""
    ) {
      toast.error("All fields are mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      const response = await Services.register(newAdmin);
      setNewAdmin({
        firstName: "",
        lastName: "",
        emailId: "",
        password: "",
        adminType: "superAdmin",
      });
      refreshFn();
      if(response.status === 200){
        toast.success("Admin Registered", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      
      
    } catch (error) {
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : "Something went wrong!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const deleteAdminFn = async () => {
    try {
      const response = await Services.deleteAdmin(deleteAdmin._id);
      if(response.status === 200){
        toast.success(`Successfully deleted ${deleteAdmin.emailId}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      handleClose();
      setDeleteAdmin({
        _id: "",
        emailId: "",
      });
      refreshFn();
    } catch (error) {
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : "Something went wrong!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const updatePassword = async () => {
    try {
      const response = await Services.updatePassword(updateAdmin._id, {
        password: updateAdmin.password,
      });
      if(response.status === 200){
      toast.success(`Password Update for ${updateAdmin.emailId}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
      handleUpdateClose();
      setUpdateAdmin({
        _id: "",
        emailId: "",
        password: "",
      });
      refreshFn();
    } catch (error) {
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : "Something went wrong!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const { _id, emailId } = params.row;
        return (
          <div className="cellAction">
            {location.state.userId === _id ||
            userData.adminType !== adminType.superAdmin ? null : (
              <div
                onClick={() => handleOpen(_id, emailId)}
                className="deleteButton"
              >
                Delete
              </div>
            )}
            <div
              onClick={() => handleUpdateOpen(_id, emailId)}
              className="updateButton"
            >
              Update
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {userData && adminsData ? (
          <>
            <div className="top">
              <div className="left" style={{ height: "400px", width: "40vw" }}>
                {/* <div className="editButton">Edit</div> */}
                <h1 className="title">Admin</h1>
                <div className="item">
                  <AccountCircleOutlinedIcon
                    style={{ color: "#7451f8" }}
                    className="itemImg"
                  />
                  <div className="details">
                    <h1 className="itemTitle">
                      {userData.firstName} {userData.lastName}
                    </h1>
                    <div className="detailItem">
                      <span className="itemKey">Email:</span>
                      <span className="itemValue">{userData.emailId}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Admin Type:</span>
                      <span className="itemValue">{userData.adminType}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="right"
                style={{
                  boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
                  padding: "20px",
                  height: "400px",
                }}
              >
                {userData.adminType === adminType.superAdmin && (
                  <div className="details">
                    <h1 className="itemTitle" style={{ marginBottom: "20px" }}>
                      Add Admin
                    </h1>
                    <div style={{ width: "350px" }}>
                      <div className="input-items">
                        <InputLabel>First Name:</InputLabel>
                        <OutlinedInput
                          size="small"
                          type="text"
                          name="firstName"
                          value={newAdmin.firstName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-items">
                        <InputLabel>Last Name:</InputLabel>
                        <OutlinedInput
                          size="small"
                          type="text"
                          name="lastName"
                          value={newAdmin.lastName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-items">
                        <InputLabel>Email Id:</InputLabel>
                        <OutlinedInput
                          size="small"
                          type="text"
                          name="emailId"
                          value={newAdmin.emailId}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-items">
                        <InputLabel>Password:</InputLabel>
                        <OutlinedInput
                          size="small"
                          type="text"
                          name="password"
                          value={newAdmin.password}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-items">
                        <InputLabel>Super Admin:</InputLabel>
                        <Select
                          size="small"
                          style={{ width: "63.5%" }}
                          name="adminType"
                          value={newAdmin.adminType}
                          onChange={handleChange}
                        >
                          <MenuItem
                            value={adminType.superAdmin}
                          >{`${adminType.superAdmin}`}</MenuItem>
                          <MenuItem
                            value={adminType.admin}
                          >{`${adminType.admin}`}</MenuItem>
                          <MenuItem
                            value={adminType.subAdmin}
                          >{`${adminType.subAdmin}`}</MenuItem>
                          <MenuItem
                            value={adminType.dataEntry}
                          >{`${adminType.dataEntry}`}</MenuItem>
                        </Select>
                      </div>
                      <Button variant="contained" onClick={registerAdmin}>
                        Add
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ height: "100vh", padding: "30px" }}>
              <h1 className="title">Admins</h1>
              <DataGrid
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterLogicOperator: GridLinkOperator.Or,
                    },
                  },
                }}
                components={{ Toolbar: QuickSearchToolbar }}
                getRowId={(row) => row._id}
                className="datagrid"
                rows={adminsData}
                columns={adminColumns.concat(actionColumn)}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete Admin?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            id : {deleteAdmin._id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            emailId : {deleteAdmin.emailId}
          </Typography>
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={deleteAdminFn}
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <Modal
        open={updateOpen}
        onClose={handleUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to update Admin password?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            id : {updateAdmin._id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
            emailId : {updateAdmin.emailId}
          </Typography>
          <InputLabel>Password</InputLabel>
          <OutlinedInput
            type="text"
            name="password"
            value={updateAdmin.password}
            required={true}
            onChange={(e) => {
              setUpdateAdmin((prevValue) => {
                return { ...prevValue, password: e.target.value };
              });
            }}
          />
          <div>
            <Button
              style={{ marginTop: "20px" }}
              color="warning"
              variant="contained"
              onClick={updatePassword}
            >
              Update
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminPage;
