import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  // GridToolbarExport,
} from "@mui/x-data-grid";
import { vohTvTypeColumns } from "../../datatablesource";
import Box from "@mui/material/Box";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
    </Box>
  );
}

const VohTvTypeDatatable = ({
  types,
  updateTagOpen,
  addTagOpen,
  // deleteVohTvType,
}) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              onClick={() => updateTagOpen(params.row._id, params.row.type)}
              className="updateButton"
            >
              Update
            </div>
            {/* <div
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this TV Type?"
                  )
                ) {
                  deleteVohTvType(params.row._id);
                }
              }}
              className="deleteButton"
            >
              Delete
            </div> */}

          </div>
          

        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Voh Tv Types
        <div onClick={addTagOpen} className="link">
          Add New
        </div>
      </div>
      <DataGrid
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        getRowId={(row) => row._id}
        className="datagrid"
        rows={types}
        columns={vohTvTypeColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "scroll",
          },
        }}
      />
    </div>
  );
};

export default VohTvTypeDatatable;
