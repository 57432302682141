import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Services } from "../../Services";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const UpdateDetails = () => {
  // useNavigate hook from react-router-dom to navigate between routes
  const navigate = useNavigate();
  // Get the collaborationId from the URL parameters
  const { collaborationId } = useParams();
  // State to store collaboration data
  const [collaborationData, setCollaborationData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    designation: "",
    companyName: "",
    collaborateInterest: [],
    companyAddress: "",
  });
  // State to store collaboration types
  const [collaborationTypes, setCollaborationTypes] = useState([]);

  // Fetch collaboration details and collaboration types on component mount
  useEffect(() => {
    // Function to fetch collaboration details by ID
    const getCollaborationDetails = async () => {
      try {
        const response = await Services.getCollaborationById(collaborationId);
        setCollaborationData(response.data);
      } catch (error) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error!! from getCollaborationDetails",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };

    // Function to fetch all collaboration types
    const fetchCollaborationTypes = async () => {
      try {
        const response = await Services.getAllCollaborationTypes();
        setCollaborationTypes(response.data);
      } catch (error) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error fetching collaboration types",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };

    getCollaborationDetails();
    fetchCollaborationTypes();
  }, [collaborationId]);

  // Handle input changes in the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCollaborationData({ ...collaborationData, [name]: value });
  };

  // Handle form submission for updating collaboration details
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = await Services.updateCollaborationById(
        collaborationId,
        collaborationData
      );
      toast.success("Collaboration details updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCollaborationData(updatedData);
      navigate(`/collaborations`);
    } catch (error) {
      console.error("Error during update:", error);
      toast.error(
        error.response?.data?.message
          ? error.response.data.message
          : "Error!! from handleSubmit",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="bg-opacity-50 flex justify-center items-center mt-12">
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl mx-auto">
            <h3 className="text-2xl font-semibold mb-4 text-center">
              Collaborate with Us
            </h3>
            {collaborationData && (
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-md font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.name}
                      onChange={handleInputChange}
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-md font-medium text-gray-700">
                      E-mail ID
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.email}
                      onChange={handleInputChange}
                      placeholder="Enter your email"
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-md font-medium text-gray-700">
                      Mobile No
                    </label>
                    <input
                      type="tel"
                      name="mobileNo"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.mobileNo}
                      onChange={handleInputChange}
                      placeholder="Enter your mobile number"
                      pattern="^[0-9]{10}$"
                      inputMode="numeric"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-md font-medium text-gray-700">
                      Designation
                    </label>
                    <input
                      type="text"
                      name="designation"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.designation}
                      onChange={handleInputChange}
                      placeholder="Enter your designation"
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-md font-medium text-gray-700">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.companyName}
                      onChange={handleInputChange}
                      placeholder="Enter your company name"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-md font-medium text-gray-700">
                      Collaborate Interest For
                    </label>
                    <select
                      name="collaborateInterest"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.collaborateInterest}
                      onChange={handleInputChange}
                    >
                      <option value="">Select an option</option>
                      {collaborationTypes.map((type) => (
                        <option key={type._id} value={type.type}>
                          {type.type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="w-full">
                    <label className="block text-md font-medium text-gray-700">
                      Company Address
                    </label>
                    <textarea
                      type="text"
                      name="companyAddress"
                      className="mt-1 block w-full border-2 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-[#0a91b1]"
                      required
                      value={collaborationData.companyAddress}
                      onChange={handleInputChange}
                      placeholder="Enter your company address"
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="py-2 px-4 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDetails;
