// import { useState } from "react";
// import RichTextEditor from "../Event/RichEditor";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import RemoveIcon from "@mui/icons-material/Remove";
// // import Image from "next/image";

// import AddIcon from "@mui/icons-material/Add";
// import { Typography } from "@mui/material";
// function PreviousEvent({ eventPreviousData, SetEventPreviousData }) {
//   const addData = () => {
//     SetEventPreviousData({
//       groupHeading: "Previous Event",
//       description: "",
//       position: 0,
//       publish: false,
//       data: [],
//     });
//   };

//   const removeData = () => {
//     SetEventPreviousData(null);
//   };

//   const handleAddPreviousEvent = () => {
//     if (eventPreviousData) {
//       const updatedData = { ...eventPreviousData };
//       updatedData.data.push({
//         videoUrl: "",
//         image: "",
//         eventLink: "",
//         fileType: "image",
//       });
//       SetEventPreviousData(updatedData);
//     }
//   };

//   const handleRemovePreviousEvent = () => {
//     if (eventPreviousData && eventPreviousData.data.length > 0) {
//       const updatedData = { ...eventPreviousData };
//       updatedData.data.pop();
//       SetEventPreviousData(updatedData);
//     }
//   };

//   const updatePreviousEventData = (index, key, value) => {
//     SetEventPreviousData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");
//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[250px] flex justify-around">
//             Previous events
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {eventPreviousData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {eventPreviousData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"groupHeading"}
//                 value={eventPreviousData.groupHeading}
//                 onChange={(e) => {
//                   SetEventPreviousData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Description
//               </label>
//               <RichTextEditor
//                 name={"description"}
//                 value={eventPreviousData.description}
//                 onChange={(e) => {
//                   SetEventPreviousData((prev) => ({
//                     ...prev,
//                     description: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={eventPreviousData.position}
//                   onChange={(e) => {
//                     SetEventPreviousData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={eventPreviousData.publish}
//                       onChange={(e) => {
//                         SetEventPreviousData((prev) => ({
//                           ...prev,
//                           publish: e.target.value === "true",
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 color: "#489FC5",
//                 fontSize: "30px",
//                 margin: "40px 0 0 68px",
//               }}
//             >
//               Previous Event Data
//               <AddCircleIcon
//                 onClick={() => handleAddPreviousEvent()}
//                 style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//               />
//               <RemoveCircleIcon
//                 onClick={() => handleRemovePreviousEvent()}
//                 style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//               />
//             </Typography>
//             <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-5"></div>
//             <div className=" w-full">
//               {eventPreviousData.data.map((item, index) => (
//                 <div
//                   className="mx-auto w-full"
//                   key={index}
//                   style={{
//                     visibility: hide ? "hidden" : "visible",
//                     height: hide ? "0" : "auto",
//                     transition: "height 0.3s ease",
//                     width: item.fileType === "video" ? "80%" : "80%", // Adjust width based on fileType
//                   }}
//                 >
//                   <div className="flex gap-8 mb-2 mx-auto">
//                     <div className="flex flex-row w-full justify-between gap-10">
//                       <div className="flex flex-col w-full">
//                         {" "}
//                         <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                           File Type
//                         </label>
//                         <select
//                           value={item.fileType}
//                           onChange={(e) =>
//                             updatePreviousEventData(
//                               index,
//                               "fileType",
//                               e.target.value
//                             )
//                           }
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         >
//                           <option value="image">Image</option>
//                           <option value="video">Video</option>
//                         </select>
//                       </div>
//                       {/* {item.fileType === "image" && ( */}
//                       <div className="flex flex-col w-full">
//                         {" "}
//                         {/* Adjusted width to full */}
//                         <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                           Image
//                         </label>
//                         <input
//                           type="file"
//                           onChange={(e) => {
//                             const file = e.target.files[0];
//                             const reader = new FileReader();
//                             reader.onloadend = () => {
//                               updatePreviousEventData(index, "image", file);
//                             };
//                             if (file) {
//                               reader.readAsDataURL(file);
//                             }
//                           }}
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         />
//                         <img
//                           style={{
//                             width: "100%",
//                             height: "auto",
//                             objectFit: "cover",
//                             borderRadius: "8px",
//                             marginTop: "10px",
//                             maxWidth: "500px", // Set max width for large images
//                           }}
//                           src={item.image}
//                           alt="Image_Uploaded"
//                         />
//                       </div>
//                       {/* )} */}
//                       {/* {item.fileType === "video" && ( */}
//                       <div className="flex flex-col w-full">
//                         <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                           Video URL
//                         </label>
//                         <input
//                           value={item.videoUrl}
//                           type="text"
//                           onChange={(e) => {
//                             updatePreviousEventData(
//                               index,
//                               "videoUrl",
//                               e.target.value
//                             );
//                           }}
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         />
//                       </div>
//                       {/* )} */}
//                     </div>
//                   </div>
//                   <div className="flex flex-col gap-8 my-6 mb-10 mx-auto">
//                     <div className="w-full">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Event Link
//                       </label>
//                       <input
//                         value={item.eventLink}
//                         type="url"
//                         onChange={(e) =>
//                           updatePreviousEventData(
//                             index,
//                             "eventLink",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>
//                   </div>
//                   <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                 </div>
//               ))}
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default PreviousEvent;

import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

function PreviousEvent({ eventPreviousData, SetEventPreviousData }) {
  const addData = () => {
    SetEventPreviousData([
      ...eventPreviousData,
      {
        groupHeading: "Previous Event",
        description: "",
        position: 0,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Previous Event group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (eventPreviousData.length > 0) {
      const updatedData = [...eventPreviousData];
      updatedData.pop();
      SetEventPreviousData(updatedData);
      toast.success("Last Previous Event group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No Previous Event groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this Previous Event section?")) {
      SetEventPreviousData(eventPreviousData.filter((_, i) => i !== index));
      toast.success("Previous Event group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddPreviousEvent = (groupIndex) => {
    const updatedData = [...eventPreviousData];
    updatedData[groupIndex].data.push({
      videoUrl: "",
      image: "",
      eventLink: "",
      fileType: "image",
    });
    SetEventPreviousData(updatedData);
  };

  const handleRemovePreviousEvent = (groupIndex) => {
    const updatedData = [...eventPreviousData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      SetEventPreviousData(updatedData);
    }
  };

  const updatePreviousEventData = (groupIndex, dataIndex, key, value) => {
    const updatedData = [...eventPreviousData];
    updatedData[groupIndex].data[dataIndex] = {
      ...updatedData[groupIndex].data[dataIndex],
      [key]: value,
    };
    SetEventPreviousData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[250px] flex justify-around">
            Previous events
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {eventPreviousData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {eventPreviousData.map((previousEventGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={previousEventGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...eventPreviousData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  SetEventPreviousData(updatedData);
                }}
              />
            </div>

            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={previousEventGroup.description}
                onChange={(e) => {
                  const updatedData = [...eventPreviousData];
                  updatedData[groupIndex].description = e.target.value;
                  SetEventPreviousData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={previousEventGroup.position}
                  onChange={(e) => {
                    const updatedData = [...eventPreviousData];
                    updatedData[groupIndex].position = e.target.value;
                    SetEventPreviousData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={previousEventGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...eventPreviousData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        SetEventPreviousData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#489FC5",
                fontSize: "30px",
                margin: "40px 0 0 68px",
              }}
            >
              Previous Event Data
              <AddCircleIcon
                onClick={() => handleAddPreviousEvent(groupIndex)}
                style={{ fontSize: "36px", margin: "0 0 0 40px" }}
              />
              <RemoveCircleIcon
                onClick={() => handleRemovePreviousEvent(groupIndex)}
                style={{ fontSize: "36px", margin: "0 0 0 20px" }}
              />
            </Typography>
            <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-5"></div>
            <div className=" w-full">
              {previousEventGroup.data.map((item, dataIndex) => (
                <div
                  className="mx-auto w-full"
                  key={dataIndex}
                  style={{
                    transition: "height 0.3s ease",
                    width: item.fileType === "video" ? "80%" : "80%",
                  }}
                >
                  <div className="flex gap-8 mb-2 mx-auto">
                    <div className="flex flex-row w-full justify-between gap-10">
                      <div className="flex flex-col w-full">
                        <label className="mb-6 text-xl font-medium text-[#489FC5]">
                          File Type
                        </label>
                        <select
                          value={item.fileType}
                          onChange={(e) =>
                            updatePreviousEventData(
                              groupIndex,
                              dataIndex,
                              "fileType",
                              e.target.value
                            )
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        >
                          <option value="image">Image</option>
                          <option value="video">Video</option>
                        </select>
                      </div>
                      <div className="flex flex-col w-full">
                        <label className="mb-6 text-xl font-medium text-[#489FC5]">
                          Image
                        </label>
                        <input
                          type="file"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              updatePreviousEventData(groupIndex, dataIndex, "image", file);
                            };
                            if (file) {
                              reader.readAsDataURL(file);
                            }
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        />
                        <img
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "8px",
                            marginTop: "10px",
                            maxWidth: "500px",
                          }}
                          src={item.image}
                          alt="Image_Uploaded"
                        />
                      </div>
                      <div className="flex flex-col w-full">
                        <label className="mb-6 text-xl font-medium text-[#489FC5]">
                          Video URL
                        </label>
                        <input
                          value={item.videoUrl}
                          type="text"
                          onChange={(e) => {
                            updatePreviousEventData(
                              groupIndex,
                              dataIndex,
                              "videoUrl",
                              e.target.value
                            );
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-8 my-6 mb-10 mx-auto">
                    <div className="w-full">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Event Link
                      </label>
                      <input
                        value={item.eventLink}
                        type="url"
                        onChange={(e) =>
                          updatePreviousEventData(
                            groupIndex,
                            dataIndex,
                            "eventLink",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                  </div>
                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default PreviousEvent;

