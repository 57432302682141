import React, { useState, useEffect } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AddIcon from "@mui/icons-material/Add";
import Sidebar from "../../../components/sidebar/Sidebar";
import { Api } from "../../../helper";
import { Link } from "react-router-dom";

const RegistrationForm = () => {
  const [registerData, setRegisterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [isModalOpenForm, setIsModalOpenForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const get_response = await Api.get(
          "/incubator/register-form?page=1&limit=50",
          "multipart/form-data"
        );

        if (get_response && get_response.data && get_response.data.data) {
          setRegisterData(get_response.data.data);
        } else {
          throw new Error("Invalid get_response structure");
        }
      } catch (error) {
        console.error("Error fetching registration data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-white page">
      <Sidebar />
      <div className="p-4 listContainer">
        <div className="bg-white w-full p-8">
          <div className="flex justify-between w-full align-center">
            <h1 className="text-3xl font-bold mb-4 text-[#3ea2d2]">
              Registration
            </h1>
            <Link to={`/incubator/registration/new`}
                style={{ textDecoration: "none" }}
              >
              <button
                // onClick={() => setIsModalOpenForm(true)}
                className="text-white bg-[#3ea2d2] from-blue-500 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2"
              >
                Add Form <AddIcon />
              </button>
            </Link>
          </div>
          <div className="p-6 bg-white shadow-md rounded-md w-[100%]">
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {registerData.length > 0
              ? registerData.map((form_data) => (
                  <div key={form_data._id} className="mb-6 border-b pb-4">
                    <div className="flex items-center mb-2">
                      <PersonOutlineIcon className="text-[#3ea2d2] text-lg mr-2" />
                      <div className="font-bold">{form_data.companyName}</div>
                    </div>
                    <div className="w-[80%]">
                      <div className="mb-2">
                        <span className="block text-sm text-gray-500">
                          Email:
                        </span>
                        <div className="text-gray-800">
                          {form_data.startupEmail}
                        </div>
                      </div>
                      <div className="mb-2">
                        <span className="block text-sm text-gray-500">
                          Founder Mobile:
                        </span>
                        <div className="text-gray-800">
                          {form_data.founderMobile}
                        </div>
                      </div>
                      <div className="mb-2">
                        <span className="block text-sm text-gray-500">
                          Founder Details:
                        </span>
                        <div className="text-gray-800">
                          {form_data.founderDetails}
                        </div>
                      </div>
                      <div className="mb-2">
                        <span className="block text-sm text-gray-500">
                          Problem Statement:
                        </span>
                        <div className="text-gray-800">
                          {form_data.problemStatement}
                        </div>
                      </div>
                    </div>

                    <div className="ml-[80%] flex p-4 mt-[-60px]">
                      <Link to={`/incubator/registration/edit/${form_data._id}`}
                              style={{ textDecoration: "none" }}
                      >
                      <button
                        className="text-white bg-[#3ea2d2] from-blue-500 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2"
                      >
                        Edit
                      </button>
                      </Link>
                      <Link to={`/incubator/registration/view/${form_data._id}`}
                              style={{ textDecoration: "none" }}
                      >
                      <button
                        className="text-white bg-[#3ea2d2] from-blue-500 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2"
                      >
                        View
                      </button>
                      </Link>
                    </div>
                  </div>
                ))
              : !loading && <p>No registration data found.</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
