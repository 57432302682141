import React, { useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { Api } from "../../../helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
const AddIncubationForm = () => {

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    companyName: "",
    startupEmail: "",
    problemStatement: "",
    industryVertical: "",
    operatingLocation: "",
    founderMobile: "",
    pitchDeck: null,
    teamEngagement: "",
    usingProduct: "",
    revenue: "",
    legalEntityStatus: "",
    investmentReceived: "",
    fundraising: "",
    vohSource: "",
    executionStage: "",
    applicantName: "",
    websiteLink: "",
    founderDetails: "",
    competitorsInfo: "",
    vision: "",
    problemToSolve: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: e.target.type === "file" ? e.target.files[0] : value,
    }));
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    console.log(formData)
    try {
      const response = await Api.post(
        "/incubator/register-form",
        formData,
        "multipart/form-data"
      );
      if (response.status === 200) {
        toast.success("Incubation Data Created", {
          position: toast.POSITION.TOP_RIGHT,
        });
  
        navigate(`/incubator/registration`);

      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error);
    } 
  };

  const handleCloseModalForm = () => {
    navigate(`/incubator/registration`);
  };

  return (
    <div className="bg-white page">
      <Sidebar />
      <div className="p-4 listContainer">
        <div className="bg-white p-5 rounded-lg w-full  h-[100%]">
          <h2 className="text-xl font-bold mb-4 text-center">
            Startup Registration Form
          </h2>
          {error && <p>Error: {error.message}</p>}
          <form onSubmit={handleSubmit}>
            {/* <form> */}
            <div className="border-b border-gray-900/10 overflow-y-auto  pb-12 ">
              <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 px-6 ">
                {/* companyName */}
                <div className="col-space-full sm:col-span-3 ">
                  <label
                    htmlFor="companyName"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Company / Startup name:
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter your startup Name"
                      type="text"
                      name="companyName"
                      id="companyName"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* companyName */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="startupEmail"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Share your Email ID and company startup email ID. (Preferably
                    Official)
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter your email"
                      type="startupEmail"
                      name="startupEmail"
                      id="startupEmail"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* startupEmail */}
                <div className="col-span-full sm:col-span-3 ">
                  <label
                    htmlFor="problemStatement"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    What is your problem statement? (Define in 100 words)
                  </label>
                  <div className="mt-2">
                    <textarea
                      onChange={handleChange}
                      placeholder="Enter your problemStatement"
                      id="problemStatement"
                      name="problemStatement"
                      maxLength="100"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    ></textarea>
                  </div>
                </div>
                {/* industryVertical */}
                <div className="col-span-full sm:col-span-3 ">
                  <label
                    htmlFor="industryVertical"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Which industryVertical do you belong to from the healthcare
                    industry?
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter your industryVertical"
                      type="text"
                      name="industryVertical"
                      id="industryVertical"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* operatingLocation */}
                <div className="col-span-full sm:col-span-3 ">
                  <label
                    htmlFor="operatingLocation"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Where is the company operating from and where is it
                    incorporated (If different operatingLocation)?
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter your operatingLocation"
                      type="text"
                      name="operatingLocation"
                      id="operatingLocation"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* founderMobile */}
                <div className="col-span-full sm:col-span-3 ">
                  <label
                    htmlFor="founderMobile"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Mobile number of the founder who is filling out this
                    application.
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter your founderMobile"
                      type="tel"
                      name="founderMobile"
                      id="founderMobile"
                      pattern="[0-9]{10}"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* pitchDeck */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="pitchDeck"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Upload your pitch deck
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      type="file"
                      id="pitchDeck"
                      name="pitchDeck"
                      accept=".pdf,.ppt,.pptx"
                      className="block w-full text-md text-gray-900 file:rounded-md file:border-0 file:p-1.5 file:text-md file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                    />
                  </div>
                </div>
                {/* teamEngagement */}
                <div className="col-span-full sm:col-span-3">
                  <label className="block text-md font-medium leading-6 text-gray-900">
                    Is the founding team working full time or part time on this
                    idea/startup?
                  </label>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="teamEngagement"
                        value="Full Time"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Full Time</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="teamEngagement"
                        value="Part-time"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Part-time</span>
                    </label>
                  </div>
                </div>
                {/* usingProduct */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="usingProduct"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Are people using your product?
                  </label>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="usingProduct"
                        value="true"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="usingProduct"
                        value="false"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </div>
                {/* revenue */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="revenue"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Do you have revenue?
                  </label>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="revenue"
                        value="true"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="revenue"
                        value="false"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </div>
                {/* legalEntityStatus */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="legalEntityStatus"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Have you formed ANY legal entity yet?
                  </label>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="legalEntityStatus"
                        value="true"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="legalEntityStatus"
                        value="false"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </div>
                {/* investmentReceived */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="investmentReceived"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Have you taken any investmentReceived yet?
                  </label>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="investmentReceived"
                        value="true"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="investmentReceived"
                        value="false"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </div>
                {/* fundraising */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="fundraising"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Are you currently fundraising?
                  </label>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="fundraising"
                        value="true"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input
                        onChange={handleChange}
                        type="radio"
                        name="fundraising"
                        value="false"
                        className="text-indigo-600"
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </div>
                {/* vohSource */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="vohSource"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    How did you hear about VOH Incubator?
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter vohSource"
                      type="text"
                      id="vohSource"
                      name="vohSource"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* executionStage */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="executionStage"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    How far along are you? (In terms of idea execution)
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter Stage"
                      type="text"
                      name="executionStage"
                      id="executionStage"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* applicantName */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="applicantName"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    What's your Name (Applicant Name)
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter applicantName"
                      type="text"
                      id="applicantName"
                      name="applicantName"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* websiteLink */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="websiteLink"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Website link if you have
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={handleChange}
                      placeholder="Enter your websiteLink"
                      type="url"
                      id="websiteLink"
                      name="websiteLink"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    />
                  </div>
                </div>
                {/* founderDetails */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="founderDetails"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Details of founder/cofounder
                  </label>
                  <div className="">
                    <textarea
                      onChange={handleChange}
                      placeholder="Enter founderDetails"
                      id="founderDetails"
                      name="founderDetails"
                      maxLength="200"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    ></textarea>
                  </div>
                </div>
                {/* competitorsInfo */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="competitorsInfo"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Who are your competitorsInfo? What do you understand about
                    your business that they don't? (Tell us about it in less than
                    100 words)
                  </label>
                  <div className="mt-2">
                    <textarea
                      onChange={handleChange}
                      placeholder="Enter competitorsInfo"
                      id="competitorsInfo"
                      name="competitorsInfo"
                      maxLength="100"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    ></textarea>
                  </div>
                </div>
                {/* vision */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="vision"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Vision of your Product and Services (Describe in 100 words)
                  </label>
                  <div className="mt-2">
                    <textarea
                      onChange={handleChange}
                      placeholder="Enter Vision"
                      id="vision"
                      name="vision"
                      maxLength="100"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    ></textarea>
                  </div>
                </div>
                {/* problemToSolve */}
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="problemToSolve"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Problem that the product or service intends to solve/help to
                    solve.
                  </label>
                  <div className="mt-2">
                    <textarea
                      onChange={handleChange}
                      placeholder="Enter problem"
                      id="problemToSolve"
                      name="problemToSolve"
                      maxLength="100"
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-md sm:leading-6"
                      style={{
                        borderColor: "#0a91b1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        outline: "none",
                      }}
                      onFocus={(e) => (e.target.style.borderColor = "#0a91b1")}
                      onBlur={(e) => (e.target.style.borderColor = "#0a91b1")}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div className="flex justify-center items-center gap-6 p-2">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={handleCloseModalForm}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddIncubationForm;
