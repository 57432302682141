// import { useState } from "react";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import RichTextEditor from "../Event/RichEditor";
// // import Image from "next/image";

// import "../new.scss";
// import { Typography } from "@mui/material";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";

// function Partners({ partnersDetailsData, setPartnersDetailsData }) {
//   const addData = () => {
//     setPartnersDetailsData({
//       groupHeading: "Partner",
//       position: 3,
//       publish: false,
//       description: "",
//       partnerTypes: [],
//       data: [],
//     });
//   };

//   const removeData = () => {
//     setPartnersDetailsData(null);
//   };

//   const handleAddPartners = () => {
//     if (partnersDetailsData) {
//       const updatedData = { ...partnersDetailsData };
//       updatedData.data.push({
//         position: partnersDetailsData.data.length + 1,
//         name: "",
//         partnerType:
//           partnersDetailsData.partnerTypes.length > 0
//             ? partnersDetailsData.partnerTypes[0]
//             : "",
//         imageSize: 100,
//         image: "",
//       });
//       setPartnersDetailsData(updatedData);
//     }
//   };

//   const handleRemovePartners = () => {
//     if (partnersDetailsData && partnersDetailsData.data.length > 0) {
//       const updatedData = { ...partnersDetailsData };
//       updatedData.data.pop();
//       setPartnersDetailsData(updatedData);
//     }
//   };

//   const handleAddPartnersTypes = () => {
//     if (partnersDetailsData) {
//       const updatedData = { ...partnersDetailsData };
//       updatedData.partnerTypes.push("");
//       setPartnersDetailsData(updatedData);
//     }
//   };

//   const handleRemovePartnersTypes = () => {
//     if (partnersDetailsData && partnersDetailsData.data.length > 0) {
//       const updatedData = { ...partnersDetailsData };
//       updatedData.partnerTypes.pop();
//       setPartnersDetailsData(updatedData);
//     }
//   };

//   const updatePartnersTypesData = (index, value) => {
//     console.log("Partners", value);
//     setPartnersDetailsData((prev) => ({
//       ...prev,
//       partnerTypes: prev.partnerTypes.map((item, i) =>
//         i === index ? value : item
//       ),
//     }));
//   };

//   const updatePartnersData = (index, key, value) => {
//     console.log("Partners", value);
//     setPartnersDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");

//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[150px] flex justify-between">
//             Partners
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {partnersDetailsData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {partnersDetailsData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12 mb-12">
//               <label
//                 htmlFor=""
//                 className="mb-6 text-xl font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"heading"}
//                 value={partnersDetailsData.groupHeading}
//                 onChange={(e) => {
//                   setPartnersDetailsData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>
//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                   Description
//                 </label>
//                 <RichTextEditor
//                   name={"description"}
//                   value={partnersDetailsData.description}
//                   onChange={(e) => {
//                     setPartnersDetailsData((prev) => ({
//                       ...prev,
//                       description: e.target.value,
//                     }));
//                   }}
//                 />
//               </div>
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={partnersDetailsData.position}
//                   onChange={(e) => {
//                     setPartnersDetailsData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                 />
//               </div>
//               <div className="flex-1">
//                 <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                   Publish
//                 </label>
//                 <select
//                   value={partnersDetailsData.publish}
//                   onChange={(e) => {
//                     setPartnersDetailsData((prev) => ({
//                       ...prev,
//                       publish: e.target.value === "true",
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                 >
//                   <option value="true">True</option>
//                   <option value="false">False</option>
//                 </select>
//               </div>
//             </div>

//             <div className=" w-11/12 mb-4">
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Partner Types
//                 <AddCircleIcon
//                   onClick={() => handleAddPartnersTypes()}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemovePartnersTypes()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               <div className="flex h-0 w-11/12 gap-8 mx-auto my-10">
//                 {partnersDetailsData.partnerTypes.map((_, index) => (
//                   <div key={index}>
//                     <div className="flex-1 ">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Partner Type
//                       </label>
//                       <input
//                         type="text"
//                         value={partnersDetailsData.partnerTypes[index]}
//                         onChange={(e) =>
//                           updatePartnersTypesData(index, e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>

//             <div>
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Partner Data
//                 <AddCircleIcon
//                   onClick={() => handleAddPartners()}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemovePartners()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               {partnersDetailsData.data.map((_, index) => (
//                 <div key={index}>
//                   <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Name
//                       </label>
//                       <input
//                         type="text"
//                         value={partnersDetailsData.data[index].name}
//                         onChange={(e) =>
//                           updatePartnersData(index, "name", e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>

//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Position
//                       </label>
//                       <input
//                         type="number"
//                         value={partnersDetailsData.data[index].position}
//                         onChange={(e) =>
//                           updatePartnersData(index, "position", e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>
//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Partner Type
//                       </label>
//                       <select
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         value={partnersDetailsData.data[index].partnerType}
//                         onChange={(e) =>
//                           updatePartnersData(
//                             index,
//                             "partnerType",
//                             e.target.value
//                           )
//                         }
//                       >
//                         {partnersDetailsData.partnerTypes.map(
//                           (partnerType, typeindex) => (
//                             <option key={typeindex} value={partnerType}>
//                               {partnerType}
//                             </option>
//                           )
//                         )}
//                       </select>
//                     </div>
//                   </div>

//                   <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
//                     <div
//                       className="gap-8 mb-8 mx-auto"
//                       style={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                       }}
//                     >
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Image
//                         <input
//                           type="file"
//                           accept="image/*"
//                           onChange={(e) => {
//                             const file = e.target.files[0];
//                             const reader = new FileReader();
//                             reader.onloadend = () => {
//                               updatePartnersData(index, "image", file);
//                             };
//                             if (file) {
//                               reader.readAsDataURL(file);
//                             }
//                           }}
//                           className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                         />
//                       </label>
//                       <img
//                         style={{
//                           width: "60px",
//                           objectFit: "cover",
//                           height: "60px",
//                         }}
//                         src={partnersDetailsData.data[index].image}
//                         alt="Image_Uploaded"
//                       />
//                     </div>
//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Image Size (%)
//                       </label>
//                       <input
//                         type="number"
//                         value={partnersDetailsData.data[index].imageSize}
//                         onChange={(e) =>
//                           updatePartnersData(index, "imageSize", e.target.value)
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>
//                   </div>

//                   <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                 </div>
//               ))}
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default Partners;





import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RichTextEditor from "../Event/RichEditor";
import { Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

function Partners({ partnersDetailsData, setPartnersDetailsData }) {
  const addData = () => {
    setPartnersDetailsData([
      ...partnersDetailsData,
      {
        groupHeading: "Partner",
        position: 3,
        publish: false,
        description: "",
        partnerTypes: [],
        data: [],
      },
    ]);
    toast.success("New Partners group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (partnersDetailsData.length > 0) {
      const updatedData = [...partnersDetailsData];
      updatedData.pop();
      setPartnersDetailsData(updatedData);
      toast.success("Last Partners group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No Partners groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this Partners section?")) {
      setPartnersDetailsData(partnersDetailsData.filter((_, i) => i !== index));
      toast.success("Partners group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddPartners = (groupIndex) => {
    const updatedData = [...partnersDetailsData];
    updatedData[groupIndex].data.push({
      position: updatedData[groupIndex].data.length + 1,
      name: "",
      partnerType: updatedData[groupIndex].partnerTypes.length > 0 ? updatedData[groupIndex].partnerTypes[0] : "",
      imageSize: 100,
      image: "",
    });
    setPartnersDetailsData(updatedData);
  };

  const handleRemovePartners = (groupIndex) => {
    const updatedData = [...partnersDetailsData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      setPartnersDetailsData(updatedData);
    }
  };

  const handleAddPartnersTypes = (groupIndex) => {
    const updatedData = [...partnersDetailsData];
    updatedData[groupIndex].partnerTypes.push("");
    setPartnersDetailsData(updatedData);
  };

  const handleRemovePartnersTypes = (groupIndex) => {
    const updatedData = [...partnersDetailsData];
    if (updatedData[groupIndex].partnerTypes.length > 0) {
      updatedData[groupIndex].partnerTypes.pop();
      setPartnersDetailsData(updatedData);
    }
  };

  const updatePartnersTypesData = (groupIndex, typeIndex, value) => {
    const updatedData = [...partnersDetailsData];
    updatedData[groupIndex].partnerTypes[typeIndex] = value;
    setPartnersDetailsData(updatedData);
  };

  const updatePartnersData = (groupIndex, partnerIndex, key, value) => {
    const updatedData = [...partnersDetailsData];
    updatedData[groupIndex].data[partnerIndex] = {
      ...updatedData[groupIndex].data[partnerIndex],
      [key]: value,
    };
    setPartnersDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[150px] flex justify-between">
            Partners
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {partnersDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {partnersDetailsData.map((partnerGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12 mb-12">
              <label
                htmlFor=""
                className="mb-6 text-xl font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"heading"}
                value={partnerGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...partnersDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setPartnersDetailsData(updatedData);
                }}
              />
            </div>
            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Description
                </label>
                <RichTextEditor
                  name={"description"}
                  value={partnerGroup.description}
                  onChange={(e) => {
                    const updatedData = [...partnersDetailsData];
                    updatedData[groupIndex].description = e.target.value;
                    setPartnersDetailsData(updatedData);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={partnerGroup.position}
                  onChange={(e) => {
                    const updatedData = [...partnersDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setPartnersDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Publish
                </label>
                <select
                  value={partnerGroup.publish}
                  onChange={(e) => {
                    const updatedData = [...partnersDetailsData];
                    updatedData[groupIndex].publish = e.target.value === "true";
                    setPartnersDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>

            <div className=" w-11/12 mb-4">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Partner Types
                <AddCircleIcon
                  onClick={() => handleAddPartnersTypes(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemovePartnersTypes(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              <div className="flex h-0 w-11/12 gap-8 mx-auto my-10">
                {partnerGroup.partnerTypes.map((_, typeIndex) => (
                  <div key={typeIndex}>
                    <div className="flex-1 ">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Partner Type
                      </label>
                      <input
                        type="text"
                        value={partnerGroup.partnerTypes[typeIndex]}
                        onChange={(e) =>
                          updatePartnersTypesData(groupIndex, typeIndex, e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Partner Data
                <AddCircleIcon
                  onClick={() => handleAddPartners(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemovePartners(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {partnerGroup.data.map((partner, partnerIndex) => (
                <div key={partnerIndex}>
                  <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Name
                      </label>
                      <input
                        type="text"
                        value={partner.name}
                        onChange={(e) =>
                          updatePartnersData(groupIndex, partnerIndex, "name", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Position
                      </label>
                      <input
                        type="number"
                        value={partner.position}
                        onChange={(e) =>
                          updatePartnersData(groupIndex, partnerIndex, "position", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Partner Type
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        value={partner.partnerType}
                        onChange={(e) =>
                          updatePartnersData(groupIndex, partnerIndex, "partnerType", e.target.value)
                        }
                      >
                        {partnerGroup.partnerTypes.map((partnerType, typeIndex) => (
                          <option key={typeIndex} value={partnerType}>
                            {partnerType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <div
                      className="gap-8 mb-8 mx-auto"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Image
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              updatePartnersData(groupIndex, partnerIndex, "image", file);
                            };
                            if (file) {
                              reader.readAsDataURL(file);
                            }
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                        />
                      </label>
                      <img
                        style={{
                          width: "60px",
                          objectFit: "cover",
                          height: "60px",
                        }}
                        src={partner.image}
                        alt="Image_Uploaded"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Image Size (%)
                      </label>
                      <input
                        type="number"
                        value={partner.imageSize}
                        onChange={(e) =>
                          updatePartnersData(groupIndex, partnerIndex, "imageSize", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                  </div>

                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default Partners;

