import axios from "axios";

export const Api = {
  post: async function (url, data, contentType = "application/json") {
    const headers = {
      "Content-Type": contentType,
      "api-key": `${process.env.REACT_APP_API_KEY}`,
      authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user"))
          ? JSON.parse(localStorage.getItem("user")).jwtToken
          : ""
      }`,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SITE_URL}${url}`,
        data,
        { headers: headers }
      );
      return response;
    } catch (error) {
      if (
        error.request.statusText === "Unauthorized" ||
        error.response.data.msg === "tokenExpired"
      ) {
        window.location.href = "/login";
      }
      throw error;
    }
  },
  get: async function (url) {
    const headers = {
      "Content-Type": "application/json",
      "api-key": `${process.env.REACT_APP_API_KEY}`,
      authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user")).jwtToken
      }`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SITE_URL}${url}`,
        {
          headers: headers,
        }
      );
      return response;
    } catch (error) {
      if (
        error.request.statusText === "Unauthorized" ||
        error.response.data.msg === "tokenExpired"
      ) {
        window.location.href = "/login";
      }
      throw error;
    }
  },
  put: async function (url, data, contentType = "application/json") {
    const headers = {
      "Content-Type": contentType,
      "api-key": `${process.env.REACT_APP_API_KEY}`,
      authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user")).jwtToken
      }`,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SITE_URL}${url}`,
        data,
        { headers: headers }
      );
      return response;
    } catch (error) {
      if (
        error.request.statusText === "Unauthorized" ||
        error.response.data.msg === "tokenExpired"
      ) {
        window.location.href = "/login";
      }
      throw error;
    }
  },
  delete: async function (url) {
    const headers = {
      "Content-Type": "application/json",
      "api-key": `${process.env.REACT_APP_API_KEY}`,
      authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user")).jwtToken
      }`,
    };
    try {
      const response = await axios.delete( `${process.env.REACT_APP_SITE_URL}${url}`, { headers: headers });
      return response;
    } catch (error) {
      if (
        error.request.statusText === "Unauthorized" ||
        error.response.data.msg === "tokenExpired"
      ) {
        window.location.href = "/login";
      }
      throw error;
    }
  }
};