// import { useState } from "react";
// import RichTextEditor from "../Event/RichEditor";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import { Typography } from "@mui/material";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// function ContactUs({ contactUsDetailsData, setContactUsDetailsData }) {
//   const addData = () => {
//     setContactUsDetailsData({
//       groupHeading: "Contact Us",
//       description: "",
//       position: 0,
//       publish: false,
//       data: [],
//     });
//   };

//   const removeData = () => {
//     setContactUsDetailsData(null);
//   };

//   const handleAddContactUs = () => {
//     if (contactUsDetailsData) {
//       const updatedData = { ...contactUsDetailsData };
//       updatedData.data.push({
//         position: updatedData.data.length + 1,
//         name: "",
//         designation: "",
//         mobile: "",
//         email: "",
//       });
//       setContactUsDetailsData(updatedData);
//     }
//   };

//   const handleRemoveContactUs = () => {
//     if (contactUsDetailsData && contactUsDetailsData.data.length > 0) {
//       const updatedData = { ...contactUsDetailsData };
//       updatedData.data.pop();
//       setContactUsDetailsData(updatedData);
//     }
//   };

//   const updateContactUsData = (index, key, value) => {
//     setContactUsDetailsData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");
//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[200px] flex justify-around">
//             Contact Us
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {contactUsDetailsData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {contactUsDetailsData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12 mb-12">
//               <label
//                 htmlFor=""
//                 className="mb-6 text-xl font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"groupHeading"}
//                 value={contactUsDetailsData.groupHeading}
//                 onChange={(e) => {
//                   setContactUsDetailsData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//               <div className="mt-8">
//                 {" "}
//                 {/* Added margin between Group Heading and Description */}
//                 <label
//                   htmlFor=""
//                   className="mb-6 text-xl font-medium text-[#489FC5]"
//                 >
//                   Description
//                 </label>
//                 <RichTextEditor
//                   name={"description"}
//                   value={contactUsDetailsData.description}
//                   onChange={(e) => {
//                     setContactUsDetailsData((prev) => ({
//                       ...prev,
//                       description: e.target.value,
//                     }));
//                   }}
//                 />
//               </div>
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={contactUsDetailsData.position}
//                   onChange={(e) => {
//                     setContactUsDetailsData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                 />
//               </div>
//               <div className="flex-1">
//                 <div>
//                   <label className="w-8/12 gap-8 ml-16 text-xl font-medium text-[#489FC5]">
//                     Publish :
//                     <select
//                       value={contactUsDetailsData.publish}
//                       onChange={(e) => {
//                         setContactUsDetailsData((prev) => ({
//                           ...prev,
//                           publish: e.target.value === "true",
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 color: "#489FC5",
//                 fontSize: "30px",
//                 margin: "40px 0 0 68px",
//               }}
//             >
//               ContactUs Data
//               <AddCircleIcon
//                 onClick={() => handleAddContactUs()}
//                 style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//               />
//               <RemoveCircleIcon
//                 onClick={() => handleRemoveContactUs()}
//                 style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//               />
//             </Typography>
//             <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//             {contactUsDetailsData.data.map((_, index) => (
//               <div>
//                 <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
//                   <div className="flex-1">
//                     <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                       Name
//                     </label>
//                     <RichTextEditor
//                       value={contactUsDetailsData.data[index].name}
//                       type="text"
//                       onChange={(e) =>
//                         updateContactUsData(index, "name", e.target.value)
//                       }
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                     />
//                   </div>

//                   <div className="flex-1">
//                     <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                       Designation
//                     </label>
//                     <RichTextEditor
//                       value={contactUsDetailsData.data[index].designation}
//                       type="text"
//                       onChange={(e) =>
//                         updateContactUsData(
//                           index,
//                           "designation",
//                           e.target.value
//                         )
//                       }
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                     />
//                   </div>
//                 </div>
//                 <div className="w-11/12 gap-8 my-6 mb-10 mx-auto">

//                   <div className="flex-1">
//                     <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                       Mobile (10 digits)
//                     </label>
//                     <RichTextEditor
//                       value={contactUsDetailsData.data[index].mobile}
//                       type="tel"
//                       pattern="^[0-9]{10}$"
//                       title="Mobile number should be 10 digits"
//                       onChange={(e) =>
//                         updateContactUsData(index, "mobile", e.target.value)
//                       }
//                       className={`bg-gray-50 border ${contactUsDetailsData.data[index].mobile.match(/^[0-9]{10}$/) ? 'border-gray-300' : 'border-red-500'} text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full`}
//                     />
//                     {!contactUsDetailsData.data[index].mobile.match(/^[0-9]{10}$/) && (
//                       <p className="text-red-500 text-sm mt-1">Mobile number must be exactly 10 digits.</p>
//                     )}
//                   </div>
//                   <div className="flex-1">
//                     <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                       Email
//                     </label>
//                     <RichTextEditor
//                       type="email"
//                       value={contactUsDetailsData.data[index].email}
//                       pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
//                       title="Please enter a valid email address"
//                       onChange={(e) =>
//                         updateContactUsData(index, "email", e.target.value)
//                       }
//                       className={`bg-gray-50 border ${contactUsDetailsData.data[index].email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) ? 'border-gray-300' : 'border-red-500'} text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full`}
//                     />
//                     {!contactUsDetailsData.data[index].email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) && (
//                       <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
//                     )}
//                   </div>
//                   <div className="flex-1">
//                     <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                       Position
//                     </label>
//                     <input
//                       value={contactUsDetailsData.data[index].position}
//                       type="number"
//                       onChange={(e) =>
//                         updateContactUsData(index, "position", e.target.value)
//                       }
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                     />
//                   </div>
//                 </div>
//                 <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               </div>
//             ))}
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default ContactUs;


import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Typography } from "@mui/material";
import { MdDelete } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";

function ContactUs({ contactUsDetailsData, setContactUsDetailsData }) {
  const addData = () => {
    setContactUsDetailsData([
      ...contactUsDetailsData,
      {
        groupHeading: "Contact Us",
        description: "",
        position: 0,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Contact Us group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (contactUsDetailsData.length > 0) {
      const updatedData = [...contactUsDetailsData];
      updatedData.pop();
      setContactUsDetailsData(updatedData);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setContactUsDetailsData(contactUsDetailsData.filter((_, i) => i !== index));
      toast.success("Contact Us group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  };

  const handleAddContactUs = (index) => {
    const updatedData = [...contactUsDetailsData];
    updatedData[index].data.push({
      position: updatedData[index].data.length + 1,
      name: "",
      designation: "",
      mobile: "",
      email: "",
    });
    setContactUsDetailsData(updatedData);
  };

  const handleRemoveContactUs = (index) => {
    const updatedData = [...contactUsDetailsData];
    if (updatedData[index].data.length > 0) {
      updatedData[index].data.pop();
      setContactUsDetailsData(updatedData);
    }
  };

  const updateContactUsData = (groupIndex, contactIndex, key, value) => {
    const updatedData = [...contactUsDetailsData];
    updatedData[groupIndex].data[contactIndex] = {
      ...updatedData[groupIndex].data[contactIndex],
      [key]: value,
    };
    setContactUsDetailsData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[200px] flex justify-around">
            Contact Us
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {contactUsDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {contactUsDetailsData.map((contactGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className="mt-1 border-2 border-solid border-[#489FC5] rounded-md"
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px",
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12 mb-12">
              <label
                htmlFor=""
                className="mb-6 text-xl font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={contactGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...contactUsDetailsData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  setContactUsDetailsData(updatedData);
                }}
              />
              <div className="mt-8">
                <label
                  htmlFor=""
                  className="mb-6 text-xl font-medium text-[#489FC5]"
                >
                  Description
                </label>
                <RichTextEditor
                  name={"description"}
                  value={contactGroup.description}
                  onChange={(e) => {
                    const updatedData = [...contactUsDetailsData];
                    updatedData[groupIndex].description = e.target.value;
                    setContactUsDetailsData(updatedData);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={contactGroup.position}
                  onChange={(e) => {
                    const updatedData = [...contactUsDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setContactUsDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
              <div className="flex-1">
                <div>
                  <label className="w-8/12 gap-8 ml-16 text-xl font-medium text-[#489FC5]">
                    Publish :
                    <select
                      value={contactGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...contactUsDetailsData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        setContactUsDetailsData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#489FC5",
                fontSize: "30px",
                margin: "40px 0 0 68px",
              }}
            >
              ContactUs Data
              <AddCircleIcon
                onClick={() => handleAddContactUs(groupIndex)}
                style={{ fontSize: "36px", margin: "0 0 0 40px" }}
              />
              <RemoveCircleIcon
                onClick={() => handleRemoveContactUs(groupIndex)}
                style={{ fontSize: "36px", margin: "0 0 0 20px" }}
              />
            </Typography>
            <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
            {contactGroup.data.map((contact, contactIndex) => (
              <div key={contactIndex}>
                <div className="flex w-11/12 gap-8 my-6 mb-10 mx-auto">
                  <div className="flex-1">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Name
                    </label>
                    <RichTextEditor
                      value={contact.name}
                      onChange={(e) =>
                        updateContactUsData(groupIndex, contactIndex, "name", e.target.value)
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                    />
                  </div>

                  <div className="flex-1">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Designation
                    </label>
                    <RichTextEditor
                      value={contact.designation}
                      onChange={(e) =>
                        updateContactUsData(
                          groupIndex,
                          contactIndex,
                          "designation",
                          e.target.value
                        )
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                    />
                  </div>
                </div>
                <div className="w-11/12 gap-8 my-6 mb-10 mx-auto">
                  <div className="flex-1">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Mobile (10 digits)
                    </label>
                    <RichTextEditor
                      value={contact.mobile}
                      onChange={(e) =>
                        updateContactUsData(groupIndex, contactIndex, "mobile", e.target.value)
                      }
                      className={`bg-gray-50 border ${contact.mobile.match(/^[0-9]{10}$/)
                        ? "border-gray-300"
                        : "border-red-500"
                        } text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full`}
                    />
                    {!contact.mobile.match(/^[0-9]{10}$/) && (
                      <p className="text-red-500 text-sm mt-1">
                        Mobile number must be exactly 10 digits.
                      </p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Email
                    </label>
                    <RichTextEditor
                      value={contact.email}
                      onChange={(e) =>
                        updateContactUsData(groupIndex, contactIndex, "email", e.target.value)
                      }
                      className={`bg-gray-50 border ${contact.email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)
                        ? "border-gray-300"
                        : "border-red-500"
                        } text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full`}
                    />
                    {!contact.email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) && (
                      <p className="text-red-500 text-sm mt-1">
                        Please enter a valid email address.
                      </p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label className="mb-6 text-xl font-medium text-[#489FC5]">
                      Position
                    </label>
                    <input
                      value={contact.position}
                      type="number"
                      onChange={(e) =>
                        updateContactUsData(
                          groupIndex,
                          contactIndex,
                          "position",
                          e.target.value
                        )
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                    />
                  </div>
                </div>
                <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              </div>
            ))}
          </form>
        </div>
      ))}
    </>
  );
}

export default ContactUs;
