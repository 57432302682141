import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import EventModeDatatable from "../../components/datatables/EventModeDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EventModesPage = () => {
  const [modesData, setModesData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = useState({
    id: "",
    mode: "",
  });
  const [error, setError] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getEventModes();
  }, [open]);

  const updateModeOpen = (id, mode) => {
    setError(false);
    setMode({
      id,
      mode,
    });
    setOpen(true);
  };

  const addModeOpen = () => {
    setError(false);
    setMode({
      id: "",
      mode: "",
    });
    setOpen(true);
  };

  const getEventModes = async () => {
    try {
      const response = await Services.getAllEventModes();
      setModesData(response.data);
      // toast.success("Event modes fetched successfully!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error fetching event modes!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const addEventMode = async (mode) => {
    if (mode.trim() === "") {
      toast.error("All fields are mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      const response = await Services.addEventMode({ mode: mode.trim() });
      handleClose();
      if (response.status === 200) {
        toast.success("Mode added successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("All fields are mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const updateEventMode = async (id, mode) => {
    if (mode.trim() === "") {
      toast.error("All fields are mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      const response = await Services.updateEventMode(id, {
        mode: mode.trim(),
      });
      handleClose();
      if (response.status === 200) {
        toast.success("Mode updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("All fields are mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const deleteEventMode = async (id) => {
    if (!id) {
      toast.error("Invalid mode ID.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!window.confirm("Are you sure you want to delete this mode?")) {
      toast.info("Deletion cancelled.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    try {
      const response = await Services.deleteEventMode(id);
      handleClose();
      if (response.status === 200) {
        toast.success("Mode deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      // Refresh the data after deletion without reloading the whole page
      await getEventModes();
    } catch (error) {
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error in deletion!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {modesData ? (
          <EventModeDatatable
            modes={modesData}
            updateModeOpen={updateModeOpen}
            addModeOpen={addModeOpen}
            deleteEventMode={deleteEventMode}
          />
        ) : (
          <Loader />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {mode.id ? "Update mode " + mode.id : "Add mode"}
            </Typography>
            <OutlinedInput
              value={mode.mode}
              name="mode"
              error={error}
              required={true}
              type="text"
              onChange={(e) => {
                if (e.target.value === "") {
                  setError(true);
                } else if (error) {
                  setError(false);
                }
                setMode((prevValue) => {
                  return { ...prevValue, [e.target.name]: e.target.value };
                });
              }}
            />
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() =>
                mode.id
                  ? updateEventMode(mode.id, mode.mode)
                  : addEventMode(mode.mode)
              }
            >
              {mode.id ? "Update" : "Add"}
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default EventModesPage;
