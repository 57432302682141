import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { Api } from "../../helper";
import RichTextEditor from '../Event/RichEditor';

const Partners = () => {
    const [partners, setPartners] = useState([]);
    const [newPartner, setNewPartner] = useState({
        heading: '',
        description: '',
        logo: '',
        link: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editPartner, setEditPartner] = useState({
        _id: '',
        heading: '',
        description: '',
        logo: '',
        link: ''
    });

    useEffect(() => {
        fetchData();
    }, [isLoading]);

    const fetchData = async () => {
        try {
            const response = await Api.get('/incubator/partner');
            if (response.data && response.data.data) {
                setPartners(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAddPartner = async () => {
        setIsLoading(true);
        try {
            const response = await Api.post('/incubator/partner', newPartner, "multipart/form-data");
            setPartners([...partners, response.data]);
            console.log('Partner added successfully:', response.data);
            setNewPartner({
                heading: '',
                description: '',
                logo: '',
                link: ''
            });
        } catch (error) {
            console.error('Error adding partner:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditPartner = async () => {
        setIsLoading(true);
        try {
            const response = await Api.put(`/incubator/partner?id=${editPartner._id}`, editPartner, "multipart/form-data");
            if (response.status === 200) {
                const updatedPartners = partners.map(partner =>
                    partner._id === editPartner._id ? response.data : partner
                );
                setPartners(updatedPartners);
                console.log(`Partner with ID ${editPartner._id} updated successfully.`);
                setEditMode(false);
                setEditPartner({
                    _id: '',
                    heading: '',
                    description: '',
                    logo: '',
                    link: ''
                });
            }
        } catch (error) {
            console.error('Error updating partner:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (editMode) {
            setEditPartner(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setNewPartner(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleOnChangeImg = (name, files) => {
        console.log(files);
        if (files) {
            if (editMode) {
                setEditPartner(prevData => ({ ...prevData, [`${name}`]: files[0] }));
            } else {
                setNewPartner(prevData => ({ ...prevData, [`${name}`]: files[0] }));
            }
        }
    };

    const handleEditClick = (partner) => {
        setEditMode(true);
        setEditPartner(partner);
    };

    const renderForm = () => {
        const partnerData = editMode ? editPartner : newPartner;
        const buttonText = editMode ? 'Save Changes' : 'Add Partner';

        return (
            <div className="mb-4">
                {/* Heading, Logo, and Link in one line */}
                <div className="flex flex-col md:flex-row mb-2 items-center">
                    <input
                        type="text"
                        placeholder="Heading"
                        name="heading"
                        value={partnerData.heading}
                        onChange={handleInputChange}
                        className="flex-1 px-4 py-2 md:mr-2 mb-2 md:mb-0 border rounded"
                    />
                    <input
                        type="file"
                        placeholder="Logo"
                        onChange={(e) => handleOnChangeImg("logo", e.target.files)}
                        className="px-4 py-2 md:mr-2 mb-2 md:mb-0 border rounded"
                    />
                    <input
                        type="text"
                        placeholder="Link"
                        name="link"
                        value={partnerData.link}
                        onChange={handleInputChange}
                        className="flex-1 px-4 py-2 md:mr-2 mb-2 md:mb-0 border rounded"
                    />
                </div>

                {/* Description field */}
                <RichTextEditor
                    name="description"
                    onChange={handleInputChange}
                    value={partnerData.description}
                />

                {/* Add or Save Changes Button */}
                <div className="flex justify-center"> {/* Center the button horizontally */}
                    <button
                        onClick={editMode ? handleEditPartner : handleAddPartner}
                        className="bg-green-500 text-white px-4 py-2 rounded mt-3"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Processing...' : buttonText}
                    </button>
                </div>

                {/* Cancel Button (only visible in Edit mode) */}
                {editMode && (
                    <div className="flex justify-center mt-2"> {/* Center the Cancel button */}
                        <button
                            onClick={() => setEditMode(false)}
                            className="bg-gray-500 text-white px-4 py-2 rounded"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const truncate = (text, maxLength) => {
        if (!text) return '';
        return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };

    return (
        <div className="bg-white page">
            <Sidebar />
            <div className="listContainer p-4">
                <h1 className="text-3xl font-bold mb-4">Partner List</h1>

                {/* Add/Edit Partner Form */}
                {renderForm()}

                {/* Partner List */}
                <div className="flex flex-wrap justify-center">
                    {partners.map(partner => (
                        <div key={partner._id} className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 p-4">
                            <div className="border p-4 rounded">
                                <h2 className="text-xl font-semibold mb-2"><b>{partner.heading}</b></h2>
                                <p><b>ID:</b> {partner._id}</p>
                                <p><b>Description:</b> {truncate(partner.description, 50)}</p>
                                <p><b>Link:</b> {truncate(partner.link, 50)}</p>
                                <p><b>Logo:</b> {truncate(partner.logo, 50)}</p>
                                <div className="flex mt-4 justify-center">
                                    <button
                                        onClick={() => handleEditClick(partner)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default Partners;
