import "./datatable.scss";
import {
    DataGrid,
    GridToolbarQuickFilter,
    GridLinkOperator,
    GridToolbarExport,
} from "@mui/x-data-grid";
import { eventTypeColumns } from "../../datatablesource";
import Box from "@mui/material/Box";
import { adminType } from "../../constants";

function QuickSearchToolbar() {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Box
            sx={{
                p: 1.5,
                pb: 0,
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(",")
                        .map((value) => value.trim())
                        .filter((value) => value !== "")
                }
            />
            {user.adminType === adminType.superAdmin && (
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            )}
        </Box>
    );
}

const CollaborationTypeDatatable = ({
    types,
    updateTagOpen,
    addTagOpen,
    // deleteCollaborationType,
}) => {
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 150,
            disableExport: true,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div
                            onClick={() => updateTagOpen(params.row._id, params.row.type)}
                            className="updateButton"
                        >
                            Update
                        </div>
                        {/* <div
                            onClick={() => deleteCollaborationType(params.row._id)}
                            className="deleteButton"
                        >
                            Delete
                        </div> */}
                    </div>
                );
            },
        },
    ];
    return (
        <div className="datatable">
            <div className="datatableTitle">
                Collaboration Types
                <div onClick={addTagOpen} className="link">
                    Add New Type
                </div>
            </div>
            <DataGrid
                initialState={{
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterLogicOperator: GridLinkOperator.Or,
                        },
                    },
                }}
                components={{ Toolbar: QuickSearchToolbar }}
                getRowId={(row) => row._id}
                className="datagrid"
                rows={types}
                columns={eventTypeColumns.concat(actionColumn)}
                pageSize={9}
                rowsPerPageOptions={[9]}
                sx={{
                    "& .MuiDataGrid-virtualScroller": {
                        overflowX: "scroll",
                    },
                }}
            />
        </div>
    );
};

export default CollaborationTypeDatatable;
