import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";

const MiscellaneousPage = ({ inputs, title }) => {
  const [miscellaneousData, setMiscellaneousData] = useState({
    privacyPolicy: "",
    incubatorImage: "",
    footerPara: "",
  });

  useEffect(() => {
    getMiscellaneous();
  }, []);

  const getMiscellaneous = async () => {
    try {
      const response = await Services.getMiscellaneous();
      if (response.data.length > 0) {
        setMiscellaneousData(response.data[0]);
      }
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Services.updateMiscellaneous(miscellaneousData);
      if (response.status === 200) {
        toast.success("Miscellaneous page updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.data.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMiscellaneousData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={onSubmit}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>
                    {input.label}
                    {input.required && "*"}
                  </label>
                  {input.name === "footerPara" ? (
                    <textarea
                      name={input.name}
                      placeholder={input.placeholder}
                      value={miscellaneousData[input.name]}
                      onChange={handleChange}
                      rows="5"
                      cols="33"
                    ></textarea>
                  ) : (
                    <input
                      type={input.type}
                      name={input.name}
                      value={miscellaneousData[input.name]}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiscellaneousPage;
