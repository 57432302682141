import "./sidebar.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import CollectionsIcon from "@mui/icons-material/Collections";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import LiveTvIcon from "@mui/icons-material/LiveTv";
import StyleIcon from "@mui/icons-material/Style";
import { Link, useNavigate } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../stores/userStore";
import GroupIcon from '@mui/icons-material/Group';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { adminType } from "../../constants";
import { TbListDetails } from "react-icons/tb";

const Sidebar = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    dispatch({ type: userActions.logout.type });
    navigate("/login");
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">VOH Admin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">LISTS</p>
          {user.adminType === adminType.subAdmin ||
            user.adminType === adminType.dataEntry ? null : (
            <Link to="/users/usertypes" style={{ textDecoration: "none" }}>
              <li>
                <AccessibilityIcon className="icon" />
                <span>Users Types</span>
              </li>
            </Link>
          )}

          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          {user.adminType === adminType.dataEntry ? null : (
            <Link to="/events/modes" style={{ textDecoration: "none" }}>
              <li>
                <StyleIcon className="icon" />
                <span>Event Modes</span>
              </li>
            </Link>
          )}
          {user.adminType === adminType.dataEntry ? null : (
            <Link to="/events/types" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Event Types</span>
              </li>
            </Link>
          )}
          {user.adminType === adminType.dataEntry ? null : (
            <Link to="/events" style={{ textDecoration: "none" }}>
              <li>
                <LocalActivityIcon className="icon" />
                <span>Events</span>
              </li>
            </Link>
          )}
          {user.adminType === adminType.dataEntry ? null : (
            <>
              <p className="title">CONTENT</p>
              <Link to="/editorials/types" style={{ textDecoration: "none" }}>
                <li>
                  <CategoryIcon className="icon" />
                  <span>Editorial Types</span>
                </li>
              </Link>
              <Link to="/editorials" style={{ textDecoration: "none" }}>
                <li>
                  <NewspaperIcon className="icon" />
                  <span>Editorials</span>
                </li>
              </Link>
              {/* <Link to="/gallerys" style={{ textDecoration: "none" }}>
                <li>
                  <CollectionsIcon className="icon" />
                  <span>Gallery</span>
                </li>
              </Link> */}
              <Link to="/vohtvs/types" style={{ textDecoration: "none" }}>
                <li>
                  <CategoryIcon className="icon" />
                  <span>Voh Tv Types</span>
                </li>
              </Link>
              <Link to="/vohtvs" style={{ textDecoration: "none" }}>
                <li>
                  <LiveTvIcon className="icon" />
                  <span>Voh Tv</span>
                </li>
              </Link>
            </>
          )}

          {user.adminType === adminType.superAdmin || user.adminType === adminType.admin ? (
            <>
              <p className="title">INFO</p>
              <Link to="/meet-the-team" style={{ textDecoration: "none" }}>
                <li>
                  <GroupIcon className="icon" />
                  <span>Meet the team</span>
                </li>
              </Link>
              <Link to="/contact-us" style={{ textDecoration: "none" }}>
                <li>
                  <ContactPageIcon className="icon" />
                  <span>Contact Us</span>
                </li>
              </Link>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <li>
                  <InfoIcon className="icon" />
                  <span>About Us</span>
                </li>
              </Link>
              <Link to="/miscellaneous" style={{ textDecoration: "none" }}>
                <li>
                  <MiscellaneousServicesIcon className="icon" />
                  <span>Miscellaneous</span>
                </li>
              </Link>
              <Link to="/newsletter" style={{ textDecoration: "none" }}>
                <li>
                  <EmailIcon className="icon" />
                  <span>News Letter</span>
                </li>
              </Link>
            </>
          ) : null}

          <>
            <p className="title">INCUBATORS</p>
            <Link to="/incubator" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>About Us</span>
              </li>
            </Link>
            <Link to="/incubator/contactUs" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Contact Us</span>
              </li>
            </Link>
            <Link to="/incubator/partners" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Partners</span>
              </li>
            </Link>
            <Link to="/incubator/our-incubators" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Our Incubatee</span>
              </li>
            </Link>
            <Link to="/incubator/mentors-and-advisors" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Mentors And Advisors</span>
              </li>
            </Link>
            <Link to="/incubator/gallery" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Gallery</span>
              </li>
            </Link>
            <Link to="/incubator/registration" style={{ textDecoration: "none" }}>
              <li>
                <CategoryIcon className="icon" />
                <span>Registration</span>
              </li>
            </Link>
          </>
          <p className="title">Collaborations</p>
          <Link
            to="/collaborations"
           
            style={{ textDecoration: "none" }}
          >
            <li>
            <TbListDetails  className="icon"  />
              <span>All Collaborations</span>
            </li>
          </Link>
          <Link
            to="/collaborations/collaborationTypes"

            style={{ textDecoration: "none" }}
          >
            <li>
              <TbListDetails className="icon" />
              <span>Collaboration Types</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <Link
            to="/admin"
            state={{ userId: user.userId }}
            style={{ textDecoration: "none" }}
          >
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>
          <li onClick={logout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
