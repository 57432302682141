import { configureStore, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState : {
        emailId: '',
        userId: '',
        adminType: ''
    },
    reducers: {
        login: (state,action)=>{
            state.emailId = action.payload.emailId;
            state.userId = action.payload.userId;
            state.adminType = action.payload.adminType;
        },
        logout: (state)=>{
            state.emailId = '';
            state.userId = '';
            state.adminType = '';
        }
    }
});

const userStore = configureStore({
    reducer : {
        user: userSlice.reducer
    }
});

export const userActions = userSlice.actions;
export default userStore;