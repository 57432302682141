import { Services } from "../../Services";
import React, { useState } from "react";
import FormInput from "./components/FormInput";
import { useDispatch } from "react-redux";
import { userActions } from "../../stores/userStore";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { toast } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
// import { adminType } from "../../constants";

// const CustomToastWithLink = () => (
//   <div>
//     <span>You have new notifications for upcoming events </span>
//     <Link to="/events/notifications">click here</Link>
//   </div>
// );


const Login = ({ handleChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    emailId: "",
    password: "",
  });

  const inputs = [
    {
      id: 1,
      name: "emailId",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await Services.login(values);
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch({
        type: userActions.login.type,
        payload: {
          emailId: response.data.emailId,
          userId: response.data.userId,
          adminType: response.data.adminType
        },
      });
      setTimeout(async ()=>{
        navigate("/");
      },1000);
    } catch (error) {
      toast.error(
        error.response.data.error ? error.response.data.error : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    setIsLoading(false);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <h1>Login</h1>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button disabled={isLoading}>
          {isLoading ? (
              <ThreeCircles
                height="30"
                width="30"
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default Login;
