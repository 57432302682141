import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { collaborationColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { adminType } from "../../constants";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function QuickSearchToolbar() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      {user.adminType === adminType.superAdmin && (
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
      )}
    </Box>
  );
}

const CollaborationsDatatable = ({ collaborations }) => {
  const [open, setOpen] = useState(false);
  const [newType, setNewType] = useState('');


  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddType = async () => {
    try {
      // Call the API to add the new type
      await Services.addCollaborationType({ type: newType });
      // Reset the newType state
      setNewType('');
      // Close the modal
      handleClose();
      toast.success('New type added successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.error('Error adding new type:', error);
      toast.success('Error while adding new type', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/collaborations/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/collaborations/update/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="deleteButton">Update</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="datatable">
        <div className="datatableTitle">
          Collaborations
          <button className="link" >
            {/* onClick={handleOpen} */}
            Add New Type
          </button>
        </div>
        <DataGrid
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          components={{ Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row._id}
          className="datagrid"
          rows={collaborations}
          columns={collaborationColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "scroll",
            },
          }}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Type
          </Typography>
          <input
            className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="text"
            value={newType}
            onChange={(e) => setNewType(e.target.value)}
            placeholder="Enter new type"
          />
          <div className="mb-2"></div>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300"
            onClick={handleAddType}
          >
            Add New Type
          </button>
        </Box>
      </Modal>

    </>
  );
};

export default CollaborationsDatatable;
